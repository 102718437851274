import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { browserHistory } from "react-router";

import Test from "./Test.js";

import Error404 from "./components/Errors/404";

import Home from "./Pages/Reseller/Home/index";
import Rfq from "./Pages/Reseller/Rfq/index";

function App() {
  console.log("version ", React.version);

  return (
    <div>
      <Router>
        <Switch>
          {/* COSTOMER RESELLER ROUTES */}
          <Route exact path="/" component={(props) => <Home {...props} />} />

          <Route
            exact
            path="/home/:agent_url?/:url_hash?"
            component={(props) => <Home {...props} />}
          />
          <Route
            exact
            path="/rfq/:agent_url?/:url_hash?"
            component={(props) => <Rfq {...props} />}
          />
          

          {/* END ADMIN ROUTES */}

          <Route component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
