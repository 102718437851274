import React, { Component } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HTML5_FMT } from "moment";
import MutextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import Tooltip from "@material-ui/core/Tooltip";
import { Editor } from "@tinymce/tinymce-react";

const styles = {
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
};

export class ExtraDetails extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
    };
  }

  openServices = () => {
    window.$("#modalFormServices").modal("show");
  };

  closePopup = () => {
    window.$("#modalFormServices").modal("hide");
  };

  SubmitWholeForm = async () => {
    const { submitExtraDetails } = this.props;

    await submitExtraDetails();
  };

  addService = async () => {
    const { addNewService } = this.props;
    await addNewService();
  };

  remove_service = async (service_id) => {
    const { removeService } = this.props;
    await removeService(service_id);
  };

  handleradio = (service_id) => {
    const { handleChangeRadio } = this.props;
    handleChangeRadio(service_id);
  };

  render() {
    const {
      values,
      handleChange,
      prevStep,
      handleChangeGeneric,
      handleRemoveSpecificRowGeneric,
      handleAddRowGeneric,
      handleFileChange,
      handleDateChange,
      handleChangeServices,
      handleChangeServicesCheckbox,
      handleEditorChange,
      handleChangeRadio
    } = this.props;
    //console.log("Extra Details values: ", values);
    return (
      <>
        <div className="nk-stepper-step active">
          <div className="col-lg-12 mt-4 mb-4">
            <div className="nk-block-head">
              <div className="nk-block-head-content d-flex">
                <div className="col-md-8 col-sm-12 col-xs-12">
                  <h5 className="nk-block-title">
                    Additional Information{" "}
                  </h5>
                </div>
                <div className="col-md-4  col-sm-12  col-xs-12 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-dim btn-outline-primary"
                    onClick={this.openServices}
                  >
                    Add Product / Services
                  </button>
                </div>

                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label" htmlFor="rfq_comments">
                  <Tooltip
                    title="Describe your complete requirements in request for quotation."
                    placement="right"
                  >
                    <em
                      className="icon ni ni-info"
                      style={{
                        color: "#6576ff",
                      }}
                    ></em>
                  </Tooltip>{" "}
                  Describe Your Requirements <sup>*</sup>
                </label>
                <div className="form-control-wrap">
                  <Editor
                    id="rfq_comments"
                    name="rfq_comments"
                    apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                    initialValue={values.rfq_comments}
                    selector="textarea#full-featured"
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                      ],
                      toolbar:
                        "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                    }}
                    onEditorChange={handleEditorChange}
                  />
                  <span
                    id="rfq_comments_error"
                    className="invalid"
                    style={{ color: "red" }}
                  >
                    {values.rfq_comments_error}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className="nk-block-head">
                <div className="nk-block-head-content">
                  <h5 className="nk-block-title ">
                    Attachement{" "}
                    <Tooltip title="User documentation" placement="right">
                      <em
                        className="icon ni ni-info"
                        style={{
                          color: "#6576ff",
                        }}
                      ></em>
                    </Tooltip>
                  </h5>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <div className="form-control-group" id="fileContainer">
                  <input
                    type="file"
                    id="add_picture"
                    className="file-block"
                    name="add_picture"
                    defaultValue={this.state.add_picture}
                    accept=".png, .jpg, .jpeg, .pdf, .doc, docx, .xlx, .xlsx, .csv"
                    onChange={handleFileChange}
                    required
                  />
                  <small>Acceptable file types: .png, .jpg, .jpeg, .pdf, .doc, .docx, .csv, .xlsx, .xls.</small>
                </div>
              </div>
            </div>

            <div className="nk-block nk-block-lg  rfq_container  mt-4 pt-4">
              <div className="nk-block-head">
                <div className="nk-block-head-content">
                  <h5 className="nk-block-title">
                    Already have an account with us?
                  </h5>
                  <div className="nk-block-des">
                    <p>Select one of the two options below and continue.</p>
                  </div>
                </div>
              </div>
              <div className="card col-sm-12">
                <div className="card-inner">
                  <ul className="custom-control-group">
                    <li>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="already_registered"
                          checked={
                            values.already_registered === "new" ? true : false
                          }
                          id="btnRadio2"
                          defaultValue="new"
                          onChange={() => this.handleradio("new")}
                        />
                        <label className="custom-control-label" htmlFor="btnRadio2">
                          Register as a new user and Submit RFQ.
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                        <input
                          type="radio"
                          className="custom-control-input"
                          name="already_registered"
                          checked={
                            values.already_registered === "registered" ? true : false
                          }
                          id="btnRadio1"
                          defaultValue="registered"
                          onChange={() => this.handleradio("registered")}
                        />
                        <label className="custom-control-label" htmlFor="btnRadio1">
                          Already have an account
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {values.already_registered === "registered" ? (
              <>
              <div className="col-lg-12">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title ">
                        Contact Information{" "}
                        <Tooltip
                          title="Please provide your email . If you don't have
                    an account with us, we will create one for you using this
                    information after the PIN verification."
                          placement="right"
                        >
                          <em
                            className="icon ni ni-info"
                            style={{
                              color: "#6576ff",
                            }}
                          ></em>
                        </Tooltip>{" "}
                      </h5>
                      <p>Please provide your email address registered with us. We will send a verification to the given email for verification pupose.</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="rfq_email">
                      Email Address <sup>*</sup>
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="rfq_email"
                        name="rfq_email"
                        onChange={handleChange}
                        defaultValue={values.rfq_email}
                        maxLength={100}
                        placeholder=""
                        required
                      />
                      <span id="rfq_email_error" className="invalid">
                        {values.rfq_email_error}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ):null}

            {values.already_registered === "new" ? (
              <>
                <div className="col-lg-12">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title ">
                        Contact Information{" "}
                        <Tooltip
                          title="Please provide your contact information. If you don't have
                    an account with us, we will create one for you using this
                    information after the PIN verification."
                          placement="right"
                        >
                          <em
                            className="icon ni ni-info"
                            style={{
                              color: "#6576ff",
                            }}
                          ></em>
                        </Tooltip>{" "}
                      </h5>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="rfq_full_name">
                      <Tooltip
                        title="Only letters and numbers plus spaces, 2 to 64 characters"
                        placement="right"
                      >
                        <em
                          className="icon ni ni-info"
                          style={{
                            color: "#6576ff",
                          }}
                        ></em>
                      </Tooltip>{" "}
                      Contact Name <sup>*</sup>
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="rfq_full_name"
                        name="rfq_full_name"
                        onChange={handleChange}
                        defaultValue={values.rfq_full_name}
                        maxLength={64}
                        minLength={2}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^a-zA-Z0-9 ]/gi,
                            ""
                          );
                        }}
                        placeholder=""
                        required
                      />
                      <span id="rfq_full_name_error" className="invalid">
                        {values.rfq_full_name_error}
                      </span>
                      <small></small>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="rfq_company">
                      <Tooltip
                        title="Only letters and numbers plus spaces, 2 to 64 characters"
                        placement="right"
                      >
                        <em
                          className="icon ni ni-info"
                          style={{
                            color: "#6576ff",
                          }}
                        ></em>
                      </Tooltip>{" "}
                      Company Name <sup>*</sup>
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="rfq_company"
                        name="rfq_company"
                        onChange={handleChange}
                        defaultValue={values.rfq_company}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^a-zA-Z0-9 ]/gi,
                            ""
                          );
                        }}
                        maxLength={64}
                        minLength={2}
                        placeholder=""
                        required
                      />

                      <span id="rfq_company_error" className="invalid">
                        {values.rfq_company_error}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="rfq_email">
                      Email Address <sup>*</sup>
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="rfq_email"
                        name="rfq_email"
                        onChange={handleChange}
                        defaultValue={values.rfq_email}
                        maxLength={100}
                        placeholder=""
                        required
                      />
                      <span id="rfq_email_error" className="invalid">
                        {values.rfq_email_error}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="rfq_phone">
                      <Tooltip
                        title="Only numbers, 7 to 14 characters"
                        placement="right"
                      >
                        <em
                          className="icon ni ni-info"
                          style={{
                            color: "#6576ff",
                          }}
                        ></em>
                      </Tooltip>{" "}
                      Phone Number <sup>*</sup>
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="rfq_phone"
                        name="rfq_phone"
                        onChange={handleChange}
                        defaultValue={values.rfq_phone}
                        maxLength={14}
                        minLength={7}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/gi,
                            ""
                          );
                        }}
                        placeholder=""
                        required
                      />
                      <span id="rfq_phone_error" className="invalid">
                        {values.rfq_phone_error}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="rfq_password">
                      <Tooltip
                        title="Minimum 8 to 50 characters. Password should contain at least 1 number, 1 capital letter, 1 special character."
                        placement="right"
                      >
                        <em
                          className="icon ni ni-info"
                          style={{
                            color: "#6576ff",
                          }}
                        ></em>
                      </Tooltip>{" "}
                      Password <sup>*</sup>
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="rfq_password"
                        name="rfq_password"
                        onChange={handleChange}
                        defaultValue={values.rfq_password}
                        maxLength={50}
                        minLength={8}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9a-zA-Z@&*$%()+!]/gi,
                            ""
                          );
                        }}
                        placeholder=""
                        required
                      />
                      <span id="rfq_password_error" className="invalid">
                        {values.rfq_password_error}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label
                      className="form-label"
                      htmlFor="rfq_confirm_password"
                    >
                      <Tooltip
                        title="Minimum 8 to 50 characters. Password and confirm password must match. Password should contain at least 1 number, 1 capital letter, 1 special character."
                        placement="right"
                      >
                        <em
                          className="icon ni ni-info"
                          style={{
                            color: "#6576ff",
                          }}
                        ></em>
                      </Tooltip>{" "}
                      Confirm Password <sup>*</sup>
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="rfq_confirm_password"
                        name="rfq_confirm_password"
                        onChange={handleChange}
                        defaultValue={values.rfq_confirm_password}
                        maxLength={50}
                        minLength={8}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9a-zA-Z@&*$%()+!]/gi,
                            ""
                          );
                        }}
                        placeholder=""
                        required
                      />
                      <span id="rfq_confirm_password_error" className="invalid">
                        {values.rfq_confirm_password_error}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        <ul
          className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end  border-top mt-5"
          style={{ marginBottom: "50px" }}
        >
          <li className="step-prev">
            <button
              type="button"
              className="btn btn-dim btn-lg btn-block btn-primary"
              onClick={prevStep}
            >
              <em className="icon ni ni-step-back"></em>
              <span>Back</span>
            </button>
          </li>
          <li className="step-next">
            <button
              type="button"
              className="btn btn-lg btn-block btn-primary"
              onClick={this.SubmitWholeForm}
            >
              <span>Continue</span>
              <em className="icon ni ni-arrow-right"></em>
            </button>
          </li>
        </ul>
        {/* SERVICES MODEL */}
        <div className="modal fade" id="modalFormServices">
          <div
            className="modal-dialog modal-dialog-top modal-xl"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add product / service</h5>
                <a
                  href="#"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
              </div>
              <div className="modal-body">
                <div className="col-sm-12 mb-4">
                  <div className="row g-gs">
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label className="form-label">
                          Select product and add
                        </label>
                        <div className="form-control-wrap">
                          <select
                            className="form-select form-control-lg js-select2"
                            datasearch="on"
                            name="select_service"
                            id="select_service"
                            onChange={handleChange}
                            defaultValue={values.select_service}
                          >
                            <option value="default_option">
                              Select product / service
                            </option>
                            {values.services_rows &&
                            values.services_rows.length > 0 ? (
                              <>
                                {values.services_rows.map((service, sid) => (
                                  <>
                                    {service.service_checked === false ? (
                                      <option
                                        key={`ser${sid}`}
                                        value={service.service_id}
                                      >
                                        {service.service_title}
                                      </option>
                                    ) : null}
                                  </>
                                ))}
                              </>
                            ) : null}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label">&nbsp;</label>
                        <div className="form-control-wrap">
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            onClick={this.addService}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row g-gs">
                  <div className="invoice-bills">
                    <div className="table-responsive">
                      <table className="table table-striped table-condensed">
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th style={{ textAlign: "left" }}>Qty</th>
                            <th style={{ textAlign: "left" }}>Unit</th>
                            <th style={{ textAlign: "left" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {values.services_rows &&
                          values.services_rows.length > 0 ? (
                            <>
                              {values.services_rows.map((service, sid) => (
                                <>
                                  {service.service_checked === true ? (
                                    <tr key={`ser${sid}`}>
                                      <td>{service.service_title}</td>

                                      <td>
                                        <div className="form-control-wrap">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="service_quantity"
                                            name="service_quantity"
                                            onChange={handleChangeServices(sid)}
                                            placeholder=""
                                            style={{
                                              width: "60px",
                                            }}
                                            value={service.service_quantity}
                                            required
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-control-wrap">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="service_unit"
                                            name="service_unit"
                                            onChange={handleChangeServices(sid)}
                                            placeholder=""
                                            onInput={(e) => {
                                              e.target.value =
                                                e.target.value.replace(
                                                  /[^a-zA-Z0-9]/gi,
                                                  ""
                                                );
                                            }}
                                            style={{
                                              width: "60px",
                                            }}
                                            value={service.service_unit}
                                            required
                                          />
                                        </div>
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        <a
                                          onClick={() =>
                                            this.remove_service(
                                              service.service_id
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "red",
                                          }}
                                        >
                                          <em className="icon ni ni-trash-fill"></em>
                                        </a>
                                      </td>
                                    </tr>
                                  ) : null}
                                </>
                              ))}
                            </>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                    <div className="example-alert mt-5 mb-2">
                      <div className="alert alert-light">
                        <strong>NOTE:</strong> After adding the product, if
                        you're unsure about the quantity, you can leave it as 0.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer bg-light">
              <div class="d-flex justify-content-end">
                <button type="button" className="btn btn-primary" onClick={() => this.closePopup()}>Close</button>
              </div>
            </div>
            </div>
          </div>
        </div>
        {/* SERVICES MODEL */}
      </>
    );
  }
}

export default ExtraDetails;
