import React, { Component } from 'react';

class TawkChat extends Component {
  componentDidMount() {
    // Tawk.to script
    var Tawk_API = Tawk_API || {};
    var Tawk_LoadStart = new Date();
    (function() {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66758c17eaf3bd8d4d13011b/1i0timfi4'; // Replace with your Tawk.to property ID
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  render() {
    return <div></div>; // You can customize the rendering if needed
  }
}

export default TawkChat;
