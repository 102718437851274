import React, { Component } from "react";
import $ from "jquery";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HTML5_FMT } from "moment";
import MutextField from "@material-ui/core/TextField";
import PinInput from "react-pin-input";

const styles = {
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
};
export class Pin_verification extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
      activeStep: 0,
      formData: {
        step1Data: "",
        step2Data: "",
      },
    };
  }
  submitForms = async () => {
    const { values, verifyPincode } = this.props;
    await verifyPincode();
  };

  resendPin = async () => {
    const { values, resendPincode } = this.props;
    await resendPincode();
  };

  submitFormQuestion = async () => {
    console.log("Control is Here");
    const { values, submitRfq } = this.props;
    await submitRfq();
  };

  render() {
    const {
      values,
      handleChange,
      theme_data,
      handleChangePin,
      handleChangePinComplete,
      prevStep,
    } = this.props;
    console.log("rfq_questions_rows: ", values);
    return (
      <>
        <div className="nk-stepper-step active">
          <div class="col-md-6 offset-md-3 text-center">
            <div class="card card-bordered">
              <div class="card-inner">
                <div className="d-flex justify-content-center  mb-4">
                  <div className="user-card bg-lighter p-3 rounded-pill">
                    <div className="user-name">
                      <span
                        className="tb-lead fs-16px"
                        style={{ fontFamily: "Nunito, sans-serif" }}
                      >
                        {values.rfq_email}
                      </span>
                    </div>
                  </div>
                </div>
                {values.showLoginButton === false ? (
                  <h5 class="card-title">Enter the pin code</h5>
                ) : null}

                <div className="row g-3">
                  {values.showLoginButton === false ? (
                    <>
                      <div className="form-group  d-flex justify-content-center">
                        <PinInput
                          length={4}
                          initialValue=""
                          focus
                          onChange={(value, index) => {
                            handleChangePin(value, index, "");
                          }}
                          onCopy={(value, index) => {
                            handleChangePinComplete(value, index, "");
                          }}
                          onBlur={(value, index) => {
                            handleChangePin(value, index, "");
                          }}
                          onKeyDown={(value, index) => {
                            handleChangePin(value, index, "");
                          }}
                          onComplete={(value, index) => {
                            handleChangePinComplete(value, index, "");
                          }}
                          defaultValue={values.pincode}
                          id="pincode"
                          name="pincode"
                          type="numeric"
                          inputMode="number"
                          style={{ padding: "15px" }}
                          inputStyle={{ borderColor: "#dbdfea" }}
                          inputFocusStyle={{ borderColor: "#6576ff" }}
                          autoSelect={false}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                      </div>
                      <div className="form-group text-center  d-flex justify-content-center">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            &nbsp;
                          </label>
                          {values.show_resend_button ? (
                            <a
                              onClick={this.resendPin}
                              style={{
                                cursor: "pointer",
                                color: "#798bff",
                              }}
                            >
                              {values.resendPinButton ? (
                                <div
                                  class="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : null}
                              Resend Pin Code{" "}
                            </a>
                          ) : (
                            <p>
                              You can resend the pin code in {values.countdown}{" "}
                              seconds.
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  ) : values.showLoginButton === true ? (
                    <>
                      <div class="example-alert">
                        <div class="alert alert-success">
                          Your request for quotation is submitted successfully.
                          To check the status of your request, click on the "Go
                          to dashboard" button. Refresh the page if you want to
                          add another RFQ.{" "}
                        </div>
                      </div>
                    </>
                  ) : null}
                  <ul
                    className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-center"
                    style={{ marginBottom: "10px" }}
                  >
                    {values.showLoginButton === true ? (
                      <>
                        <li className="step-next">
                          <a
                            className="btn btn-block btn-success"
                            href={values.final_login_url}
                            target="_blank"
                          >
                            Go to dashboard
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        {values.verifyPasswordButton ? (
                          <li className="step-next">
                            <button
                              className="btn btn-lg btn-primary"
                              type="button"
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span>Processing...</span>
                            </button>
                          </li>
                        ) : (
                          <>
                            <li className="step-prev">
                              <button
                                type="button"
                                className="btn btn-dim btn-lg btn-primary"
                                onClick={prevStep}
                              >
                                <em class="icon ni ni-step-back"></em>
                                <span>Back</span>
                              </button>
                            </li>
                            <li className="step-next">
                              <button
                                className="btn btn-lg btn-primary"
                                onClick={this.submitForms}
                              >
                                <span>Submit</span>
                                <em class="icon ni ni-arrow-right"></em>
                              </button>
                            </li>
                          </>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Pin_verification;
