export const COMPANY_NAME = "OnBoardSoft - Online Onboarding Software";
export const COMPANY_WEBSITE = "https://onboardsoft.com/";
export const LOGO = "https://my.onboardsoft.me/assets/images/logo.png";
export const LOGO_VERIFY = "../../assets/images/domain_verify.gif";
export const VENDOR_SIGNATURE =
  "https://my.onboardsoft.me/assets/images/vendor_signor_signature_file.png";
export const VENDOR_SIGNATURE_PATH =
  "https://my.onboardsoft.me/assets/images/vendor_signor_signature_file.png";
export const API_URL = "https://my.onboardsoft.me:3088/";
//export const APP_URL = "http://localhost:3000/";
export const APP_URL = "https://my.onboardsoft.me/";
export const APP_LIVE_URL = "https://my.onboardsoft.me/";

export const THEME_COLOR = "#02274d";
export const THEME_TEXT_COLOR = "white";

export const columnsServices = (is_msa_signed, defaultSettings) => {
  return [
    { name: "Title" },
    {
      name: "Description",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              href="#"
              class="btn"
              style={{
                backgroundColor: THEME_COLOR,
                color: THEME_TEXT_COLOR,
              }}
              disabled={
                is_msa_signed === "No" || defaultSettings === "missing"
                  ? true
                  : false
              }
            >
              View
            </button>
          );
        },
      },
    },
    { name: "Nrc($)" },
    { name: "Mrc($)" },
    { name: "Rate Type" },
    { name: "Rate($)" },
    {
      name: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              href="#"
              class="btn"
              style={{
                backgroundColor: THEME_COLOR,
                color: THEME_TEXT_COLOR,
              }}
              disabled={
                is_msa_signed === "No" || defaultSettings === "missing"
                  ? true
                  : false
              }
            >
              Sign
            </button>
          );
        },
      },
    },
  ];
};

export const columnsClients = () => {
  return [
    { name: "ID" },
    { name: "COMPANY NAME" },
    { name: "ADDRESS" },
    { name: "CITY" },
    { name: "STATE" },
    { name: "COUNTRY" },
    { name: "ZIP" },
    { name: "PHONE" },
    { name: "NOTICES NAME" },
    { name: "NOTICES EMAIL" },
    // { name: "BILLING NAME" },
    // { name: "BILLING EMAIL" },
    // { name: "BILLING PHONE" },
    // { name: "BILLING ADDRESS" },
    // { name: "BILLING CITY" },
    // { name: "BILLING STATE" },
    // { name: "BILLING ZIP" },
    // { name: "BILLING COUNTRY" },
  ];
};

var date = new Date();
export const dateString = new Date(
  date.getTime() - date.getTimezoneOffset() * 60000
)
  .toISOString()
  .split("T")[0];

export const dateFirstString = new Date(date.getFullYear(), date.getMonth(), 2)
  .toISOString()
  .split("T")[0];
