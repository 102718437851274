import React, { createRef, Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";

import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";
import { getAgentServiceDescription } from "../../../config/reseller_api_calls";
import {
  ValidateEmail,
  axiosConfig,
  normalize,
  ValidatePasswordPattern,
} from "../../../config/utility";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Theme1 } from "../../../components/Themes/theme-1";
import { Theme3 } from "../../../components/Themes/theme-3";
import { Theme5 } from "../../../components/Themes/theme-5";

import Error404 from "../../../components/Errors/404";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/FormLoader";
import helpherClass from "../../../config/helperClass";

import { Questions } from "../../../components/RfqOnePage/questions";
import { Pin_verification } from "../../../components/RfqOnePage/pin_verification";
import { ExtraDetails } from "../../../components/RfqOnePage/extra";

import Tooltip from "@material-ui/core/Tooltip";
import { Editor } from "@tinymce/tinymce-react";

import PinInput from "react-pin-input";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import Select from "react-select";
import countryList from "react-select-country-list";

import { PhoneNumberUtil } from "google-libphonenumber";

const auth = new helpherClass();

export default class Rfq extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();
    var datestring_today = new Date().toISOString().slice(0, 10);
    var datestring = new Date().toISOString().slice(0, 10);
    super();
    this.state = {
      errorEmail: "",
      errorPass: "",
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      password: "",
      errorMessage: "",
      errorMessageTitle: "",
      successMessage: "",
      disabled: false,
      loader: "",
      successMessageEmail: "",
      resellerData: [],
      tableLoader: true,
      res_accountno: "",
      rfq_comments_error: "",
      res_url: "",
      res_hash: "",
      res_title: "",
      pageError: "",
      selected_theme: "1",
      theme_data: [],
      invoiceLoader: true,
      rfq_answers: [{}],

      step: 1,
      currentStep: "",
      selectedFiles: [],
      file_upload_rows: [{}],
      services_rows: [{}],
      service_grand_total: 0,
      service_grand_sub_total: 0,
      //Additional Information Entries
      rfq_full_name: "",
      rfq_full_name_error: "",
      rfq_company: "",
      rfq_company_error: "",
      rfq_email: "",
      rfq_email_error: "",
      rfq_phone: "",
      rfq_phone_error: "",
      rfq_address: "",
      rfq_address_error: "",
      rfq_city: "",
      rfq_country: "",
      rfq_country_error: "",
      rfq_city_error: "",
      rfq_state: "",
      rfq_state_error: "",
      rfq_zip: "",
      rfq_zip_error: "",
      rfq_password: "",
      rfq_password_error: "",
      rfq_confirm_password: "",
      rfq_confirm_password_error: "",
      rfq_delivery_address: "",
      rfq_comments: "",
      delivery_date: datestring_today,
      add_picture: "",
      add_picture_name: "",
      additionalErrorMessage: "",
      //Discount Entries
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
      discount_reason: "",
      //Shipping Entries
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
      //Tax Details
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
      tax_reason: "",
      select_service: "",
      remove_service: "",
      services_listing: [],
      pincode: "",
      show_resend_button: false,
      resendPinButton: false,
      redirectToClientPortalBtn: false,
      resend_pin_text: "",
      countdown: 120,
      selected_agent_hash: "",
      verifyPasswordButton: false,
      showLoginButton: false,
      final_login_url: "",
      already_registered: "new",
      rfq_questions_rows: [],
      currentStepIndex: "",
      pin_submit_button_loader: false,
      extraDetails_submit_button_loader: false,
    };
    this.rfq_questions_rows = [];
    this.rfq_questions_groups = [];
    this.countryOptions = countryList().getData();
    this.phoneInputRef = createRef();
  }

  async componentDidMount() {
    let agent_url = this.props.match.params.agent_url;
    let agent_hash = this.props.match.params.url_hash;
    //let rfq_accountno = this.props.match.params.urlaccountno;
    let urlElements = window.location.href.split("/");
    //console.log("CUrrent URL: ", urlElements[2]);
    let host_url = urlElements[2];
    //console.log("URL Details: ", agent_url);
    // console.log("URL Details: ", agent_hash);
    //console.log("rfq_accountno: ", rfq_accountno);
    //if (agent_url !== "" && agent_hash !== "") {
    //Errors
    //404 and no_reseller
    //404 and no_agent
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/check_sharelink_detail",
          JSON.stringify({
            agent_url: agent_url,
            agent_hash: agent_hash,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            host_url: host_url,
          }),
          axiosConfig
        )
        .then((response) => {
          console.log("check_sharelink_detail response.data : ", response.data);
          if (
            response.data.status === 404 ||
            response.data.status === 422 ||
            response.data.errors !== ""
          ) {
            this.setState({
              errorMessage: "",
              successMessageEmail: "",
              show_form: false,
              selected_theme: "",
              invoiceLoader: false,
              pageError:
                "Sorry we are unable to process your request. Please contact admin for more details.",
            });
          }

          if (
            response.data.status === 404 &&
            response.data.message === "no_reseller"
          ) {
            this.setState({
              errorMessage: "",
              pageError:
                "Reseller does not exists. Please contact support or try again with correct URL.",
              successMessageEmail: "",
              show_form: false,
              invoiceLoader: false,
              selected_theme: "",
            });
          } else if (response.data.status === 403) {
            this.setState({
              errorMessage: "There is some error.",
              successMessageEmail: "",
              show_form: false,
              pageError: "",
              selected_theme: "",
            });
          } else if (
            response.data.status === 200 &&
            response.data.message === "no_landing"
          ) {
            this.setState({
              errorMessage: "",
              selected_theme: "6",
              invoiceLoader: false,
              tableLoader: true,
            });
            window.location.replace(response.data.register_url);
          } else if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            let sharelink_services = [];

            if (
              response.data.sharelinkData.sharelink_services &&
              response.data.sharelinkData.sharelink_services.length > 0
            ) {
              let all_services = response.data.sharelinkData.sharelink_services;
              for (let i = 0; i < all_services.length; i++) {
                let innerArray = {
                  service_checked: false,
                  service_id: all_services[i]["id"],
                  service_title: all_services[i]["service_title"],
                  service_price: all_services[i]["rate"],
                  service_quantity: 1,
                  service_unit: "",
                  service_sub_total: all_services[i]["rate"] * 1,
                };
                sharelink_services.push(innerArray);
              }
            }
            if (this.rfq_questions_rows.length === 0) {
              let rfq_questions_rows = [];
              if (
                response.data.sharelinkData.rfqQuestionGroupsListing &&
                response.data.sharelinkData.rfqQuestionGroupsListing.length > 0
              ) {
                const groupArray =
                  response.data.sharelinkData.rfqQuestionGroupsListing
                    .map((item) => ({
                      group_id: item.group_id,
                      group_title: item.group_title,
                      group_desc: item.group_desc,
                      group_sequence: item.group_sequence,
                    }))
                    .sort((a, b) => a.group_sequence - b.group_sequence);
                this.rfq_questions_groups = groupArray;
                if (
                  response.data.sharelinkData.rfqQuestionsListing &&
                  response.data.sharelinkData.rfqQuestionsListing.length > 0
                ) {
                  let all_questions =
                    response.data.sharelinkData.rfqQuestionsListing;
                  for (let i = 0; i < all_questions.length; i++) {
                    let innerArray1 = {
                      question_answered:
                        all_questions[i]["question_options"] &&
                        all_questions[i]["question_options"].length > 0 &&
                        all_questions[i]["question_type"] !== "checkbox"
                          ? true
                          : false,
                      question_id: all_questions[i]["id"],
                      question_title: all_questions[i]["question_title"],
                      question_type: all_questions[i]["question_type"],

                      all_answers: all_questions[i]["question_options"],
                    };
                    let dynamicPropertyName =
                      "selected_answer_" + all_questions[i]["id"];
                    innerArray1[dynamicPropertyName] =
                      all_questions[i]["question_options"] &&
                      all_questions[i]["question_options"].length > 0 &&
                      all_questions[i]["question_type"] !== "checkbox"
                        ? all_questions[i]["question_options"][0]["id"]
                        : all_questions[i]["question_options"] &&
                          all_questions[i]["question_options"].length > 0 &&
                          all_questions[i]["question_type"] === "checkbox"
                        ? []
                        : "";
                    innerArray1["field_name"] = dynamicPropertyName;
                    innerArray1["isMandatory"] =
                      all_questions[i]["isMandatory"];
                    innerArray1["group_id"] = all_questions[i]["group_id"];
                    rfq_questions_rows.push(innerArray1);
                  }
                }
              }

              this.rfq_questions_rows = rfq_questions_rows;
            }
            console.log("Start rfq_questions_rows: ", this.rfq_questions_rows);
            this.setState({
              errorMessage: "",
              selected_theme:
                response.data.sharelinkData.themes[0]["theme_identifier"],
              theme_data: response.data.sharelinkData,
              services_rows: sharelink_services,
              rfq_questions_rows: this.rfq_questions_rows,
              selected_agent_hash: agent_hash,
              selected_agent_identifier: agent_url,
              tableLoader: false,
              invoiceLoader: false,
              // currentStep: this.rfq_questions_groups && this.rfq_questions_groups.length > 0 ? this.rfq_questions_groups[0].group_id : "extra",
            });

            if (this.rfq_questions_rows.length === 0) {
              this.nextStep("extra", "1");
            } else {
              this.nextStep(this.rfq_questions_groups[0].group_id, "1");
            }
          }
        });
    } catch (error) {
      this.setState({
        errorMessage: "",
        successMessageEmail: "",
        selected_theme: "",
        show_form: false,
        invoiceLoader: false,
        pageError:
          "Please enter the valid URL or contact admin for more details",
      });
      console.log("catch : ", error);
    }
  }

  handleFocus = () => {
    // Move cursor to the end of the input value
    const input = this.phoneInputRef.current;
    if (input) {
      const value = input.value;
      input.setSelectionRange(value.length, value.length);
    }
  };

  formSubmit = () => {
    console.log("Parent Submit Form: ");
    //e.preventDefault();
    let { name, email, phone, subject, message } = this.state;

    console.log("name: ", name);
    console.log("email: ", email);
    console.log("phone: ", phone);
    console.log("subject: ", subject);
    console.log("message: ", message);
  };

  submitRfq = async () => {
    const { rfq_answers } = this.state;
    console.log("In submitRfq");
    let rfq_questions_rows = this.rfq_questions_rows;
    console.log("rfq_answers: ", rfq_questions_rows);
    let currentStep = this.state.currentStep;
    var is_validated = true;
    const errorelements = document.querySelectorAll(".field_errorMsg");
    errorelements.forEach((element) => {
      element.remove();
    });
    if (rfq_questions_rows && rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        if (
          rfq_questions_rows[i].question_answered === false &&
          rfq_questions_rows[i].isMandatory === 1 &&
          rfq_questions_rows[i].group_id === currentStep
        ) {
          is_validated = false;
          var fieldName = rfq_questions_rows[i].field_name;

          var elements = document.getElementsByName(fieldName);
          if (elements.length > 0) {
            var element = elements[0];
            var el_id = element.id;
            var el_type = element.type;
            if (element) {
              let colElement =
                element.parentElement.parentElement.parentElement;
              if (el_type === "select-one") {
                colElement = colElement.parentElement;
              }
              const formGroupElement = colElement.querySelector(".form-group");
              if (formGroupElement) {
                formGroupElement.insertAdjacentHTML(
                  "afterend",
                  '<p class="field_errorMsg" style="color: red;font-size: 12px; margin-top: -20px;">* This field is mandatory</p>'
                );
                setTimeout(() => {
                  const errorMsg = formGroupElement.nextElementSibling;
                  if (
                    errorMsg &&
                    errorMsg.classList.contains("field_errorMsg")
                  ) {
                    errorMsg.remove();
                  }
                }, 4000);
              }
            }
          }
        }
      }
    }

    if (is_validated) {
      //this.setState({rfq_questions_rows: rfq_questions_rows});
      const lastGroupId =
        this.rfq_questions_groups[this.rfq_questions_groups.length - 1]
          .group_id;
      const currentIndex = this.rfq_questions_groups.findIndex(
        (group) => group.group_id === this.state.currentStep
      );

      if (
        currentIndex !== -1 &&
        currentIndex === this.rfq_questions_groups.length - 1
      ) {
        this.nextStep("extra", this.rfq_questions_groups.length + 1);
      } else {
        const nextGroupId =
          this.rfq_questions_groups[currentIndex + 1].group_id;
        this.nextStep(nextGroupId, Number(this.state.currentStepIndex) + 1);
      }
      document
        .getElementById("start_container")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
    } else {
      document
        .querySelectorAll(".field_errorMsg")[0]
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      this.setState({
        errorMessage: "Please answer all the questions.",
        errorMessageTitle: "Missing Information",
      });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
          errorMessageTitle: "Error",
        });
      }, 4000);
    }
  };

  applyShipping = async () => {
    const { service_grand_total, service_grand_sub_total } = this.state;
    console.log(
      "applyDiscount: " +
        this.state.shipping_title +
        " " +
        this.state.shipping_value +
        " " +
        this.state.shipping_value_total +
        " service_grand_total: " +
        service_grand_total +
        " service_grand_sub_total: " +
        service_grand_sub_total
    );

    let shipping_value_total = 0;

    shipping_value_total = parseFloat(this.state.shipping_value);

    this.setState({
      shipping_value_total: shipping_value_total,
    });

    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
    //await this.calculateTotalRate();
  };

  removeShipping = async () => {
    this.setState({
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
    });
    await this.applyDiscount();
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  applyDiscount = async () => {
    const { service_grand_total, service_grand_sub_total } = this.state;
    console.log(
      "applyDiscount: " +
        this.state.discount_value +
        " " +
        this.state.discount_type +
        " " +
        this.state.discount_reason +
        " service_grand_total: " +
        service_grand_total +
        " service_grand_sub_total: " +
        service_grand_sub_total
    );
    let total_with_discount = service_grand_total;
    let discount_value_total = 0;
    if (this.state.discount_type === "amount") {
      total_with_discount =
        parseFloat(total_with_discount) + parseFloat(this.state.discount_value);
      discount_value_total = parseFloat(this.state.discount_value);
      this.setState({
        discount_value_total: discount_value_total,
      });
    } else if (this.state.discount_type === "percentage") {
      let percentage = this.calculatePercent(
        parseFloat(this.state.discount_value),
        parseFloat(service_grand_sub_total)
      );
      total_with_discount =
        parseFloat(total_with_discount) + parseFloat(percentage);
      discount_value_total = parseFloat(percentage);
      this.setState({
        discount_value_total: discount_value_total,
      });
    }
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
    //await this.calculateTotalRate();
  };

  removeDiscount = async () => {
    this.setState({
      discount_reason: "",
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
    });
    await this.applyDiscount();
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  applyTax = async () => {
    const {
      service_grand_total,
      service_grand_sub_total,
      discount_value_total,
    } = this.state;

    console.table({
      applyTax: "",
      tax_value: parseFloat(this.state.tax_value),
      tax_type: this.state.tax_type,
      service_grand_total: parseFloat(service_grand_total),
      service_grand_sub_total: parseFloat(service_grand_sub_total),
      discount_value_total: parseFloat(discount_value_total),
    });

    let tax_value_total = 0;
    if (this.state.tax_type === "amount") {
      tax_value_total = parseFloat(this.state.tax_value);
      this.setState({
        tax_value_total: tax_value_total,
      });
    } else if (this.state.tax_type === "percentage") {
      let discount_difference =
        parseFloat(service_grand_sub_total) - parseFloat(discount_value_total);
      let percentage = this.calculatePercent(
        parseFloat(this.state.tax_value),
        parseFloat(discount_difference)
      );

      console.log("Tax Percentage: ", percentage);
      tax_value_total = parseFloat(percentage);
      this.setState({
        tax_value_total: tax_value_total,
      });
    }
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
  };

  removeTax = async () => {
    this.setState({
      tax_reason: "",
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
    });
    await this.applyTax();
  };

  fieldValidation = () => {
    let {
      rfq_full_name,
      rfq_company,
      rfq_email,
      rfq_phone,
      rfq_password,
      rfq_confirm_password,
      rfq_comments,
      already_registered,
      rfq_address,
      rfq_city,
      rfq_state,
      rfq_zip,
      rfq_country,
    } = this.state;
    if (already_registered === "new") {
      if (rfq_comments === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "Please enter your requirements.",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_additional")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_full_name === "") {
        this.setState({
          rfq_full_name_error: "Full name is required",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          extraDetails_submit_button_loader: false,
          rfq_country_error: "",
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_company === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "Company name is required",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_email === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "Email address is required.",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (ValidateEmail(rfq_email) === false) {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "Please enter a valid email address.",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_phone === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "Phone number is required.",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      }  else if (!this.isPhoneValid(rfq_phone)) {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "Please enter a valid phone number.",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_address === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "Address is required",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_zip_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_city === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "City is required",
          rfq_state_error: "",
          rfq_zip_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_country === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_zip_error: "",
          rfq_country_error: "Country is required",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_state === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "State is required",
          rfq_zip_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_zip === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_zip_error: "Postal Code is required",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_password === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "Password is required.",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_password.length < 8) {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "Use 8 characters or more for your password.",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (ValidatePasswordPattern(rfq_password) === false) {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_password_error:
            "Password should contain at least 1 number, 1 capital letter, 1 special character, and be at least 8 characters long.",
          rfq_phone_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (rfq_confirm_password === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "Confirm password is required.",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
      }
      return true;
    } else {
      if (rfq_email === "") {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "Email address is required.",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else if (ValidateEmail(rfq_email) === false) {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "Please enter a valid email address.",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container_contact")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        return false;
      } else {
        this.setState({
          rfq_full_name_error: "",
          rfq_company_error: "",
          rfq_email_error: "",
          rfq_phone_error: "",
          rfq_password_error: "",
          rfq_confirm_password_error: "",
          rfq_comments_error: "",
          rfq_address_error: "",
          rfq_city_error: "",
          rfq_state_error: "",
          rfq_country_error: "",
        });
      }
      return true;
    }
  };

  submitExtraDetails = async () => {
    console.log("In submitExtraDetails");
    this.setState({
      errorMessage: "",
      successMessage: "",
      extraDetails_submit_button_loader: true,
    });
    const {
      rfq_full_name,
      rfq_company,
      rfq_email,
      rfq_phone,
      rfq_password,
      rfq_confirm_password,
      rfq_comments,
      add_picture,
      add_picture_name,
      rfq_questions_rows,
      services_rows,
      selected_agent_identifier,
      selected_agent_hash,
      already_registered,
    } = this.state;
    if (this.fieldValidation() === true) {
      console.log("already_registered: ", already_registered);
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "request_for_quote/generate_pin",
            JSON.stringify({
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              rfq_email: rfq_email,
              rfq_full_name: rfq_full_name,
              rfq_company: rfq_company,
              selected_agent_hash: selected_agent_hash,
              selected_agent_identifier: selected_agent_identifier,
              already_registered: already_registered,
            }),
            axiosConfig
          )
          .then((response) => {
            console.log("submitExtraDetails response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "already_exists"
            ) {
              this.setState({
                errorMessage: "Email address already exists.",
                disabled: false,
                verifyDetailsButton: false,
                extraDetails_submit_button_loader: false
              });
              document
                .getElementById("start_container")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setInterval(() => {
                this.setState({
                  errorMessage: "",
                  successMessage: "",
                });
              }, 4000);
              return false;
            } else if (
              response.data.status === 404 &&
              response.data.message === "not_found"
            ) {
              this.setState({
                errorMessage: "Email address does not found.",
                disabled: false,
                verifyDetailsButton: false,
                extraDetails_submit_button_loader: false
              });
              document
                .getElementById("start_container")
                ?.scrollIntoView({ top: 0, behavior: "smooth" });
              setInterval(() => {
                this.setState({
                  errorMessage: "",
                  successMessage: "",
                });
              }, 4000);
              return false;
            } else if (
              response.data.status === 200 &&
              response.data.message === "pin_sent"
            ) {
              this.setState({
                successMessage:
                  "We have sent an email to " +
                  rfq_email +
                  ". To complete the verification process, please check your inbox and enter the code you received here.",
                errorMessage: "",
                fieldsHide: true,
                verifyDetailsButton: false,
                countdown: 120,
                resend_pin_text: "You can resend the pincode in 120 seconds.",
              });
              setTimeout(() => {
                this.setState({
                  show_resend_button: true,
                  redirectToClientPortalBtn: true,
                });
              }, 120000);
              setInterval(() => {
                this.setState((prevState) => ({
                  countdown: prevState.countdown - 1,
                }));
              }, 1000);
              this.nextStep("pincode");
            }
            this.setState({
              extraDetails_submit_button_loader: false,
            });
          });
      } catch (error) {
        this.setState({
          errorMessage: "There is some error while processing your request.",
          disabled: false,
          verifyDetailsButton: false,
          extraDetails_submit_button_loader: false
        });
        document
          .getElementById("start_container")
          ?.scrollIntoView({ top: 0, behavior: "smooth" });
        setInterval(() => {
          this.setState({
            errorMessage: "",
            successMessage: "",
            extraDetails_submit_button_loader: false
          });
        }, 4000);
        return false;
        console.log("catch : ", error);
      }
    }
  };

  verifyPincode = async () => {
    //e.preventDefault();
    let {
      rfq_full_name,
      rfq_company,
      rfq_email,
      rfq_phone,
      rfq_password,
      rfq_confirm_password,
      rfq_comments,
      add_picture,
      add_picture_name,
      //rfq_questions_rows,
      services_rows,
      selected_agent_identifier,
      selected_agent_hash,
      pincode,
      reseller_url,
      already_registered,
      rfq_address,
      rfq_city,
      rfq_state,
      rfq_zip,
      rfq_country,
    } = this.state;

    this.setState({
      verifyPasswordButton: true,
      pin_submit_button_loader: true,
    });
    //if (this.fieldValidationUsername() === true) {
    const formData = new FormData();
    formData.append("token", process.env.REACT_APP_API_TOKEN);
    formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
    formData.append("file", add_picture);
    formData.append("fileName", add_picture_name);
    formData.append("email", rfq_email);
    formData.append("pincode", pincode);
    formData.append("rfq_full_name", rfq_full_name);
    formData.append("rfq_company", rfq_company);
    formData.append("rfq_phone", rfq_phone);
    formData.append("rfq_password", rfq_password);
    formData.append("rfq_comments", rfq_comments);
    formData.append("rfq_country", rfq_country);
    formData.append(
      "rfq_questions_rows",
      JSON.stringify(this.rfq_questions_rows)
    );
    formData.append("services_rows", JSON.stringify(services_rows));
    formData.append("selected_agent_identifier", selected_agent_identifier);
    formData.append("selected_agent_hash", selected_agent_hash);
    formData.append("reseller_url", reseller_url);
    formData.append("already_registered", already_registered);
    formData.append("rfq_address", rfq_address);
    formData.append("rfq_city", rfq_city);
    formData.append("rfq_state", rfq_state);
    formData.append("rfq_zip", rfq_zip);

    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "request_for_quote/verify_pincode",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((response) => {
          console.log("verifyPincode response.data : ", response.data);
          if (
            response.data.status === 404 &&
            response.data.message === "already_exists"
          ) {
            this.setState({
              errorMessage: "Account already exists.",
              disabled: false,
              verifyPasswordButton: false,
              successMessage: "",
            });
            setTimeout(() => {
              this.setState({
                errorMessage: "",
              });
            }, 4000);
          }

          if (
            response.data.status === 404 &&
            response.data.message === "pin_not_found"
          ) {
            this.setState({
              errorMessage: "Incorrect or expired pincode.",
              disabled: false,
              verifyPasswordButton: false,
              successMessage: "",
            });
            setTimeout(() => {
              this.setState({
                errorMessage: "",
              });
            }, 4000);
          }

          if (
            response.data.status === 404 &&
            response.data.message !== "already_exists" &&
            response.data.message !== "pin_not_found"
          ) {
            this.setState({
              errorMessage: "There is some error. Please try again later.",
              disabled: false,
              verifyPasswordButton: false,
              successMessage: "",
            });
            setTimeout(() => {
              this.setState({
                errorMessage: "",
              });
            }, 4000);
          }

          if (response.data.status === 403) {
            this.setState({
              errorMessage: response.data.message,
              disabled: false,
              verifyPasswordButton: false,
              successMessage: "",
            });
            setTimeout(() => {
              this.setState({
                errorMessage: "",
              });
            }, 4000);
          }

          if (
            response.data.status === 200 &&
            response.data.message === "success"
          ) {
            this.setState({
              successMessage:
                "Your request for quotation is submitted successfully. To check the status of your request, please check your email and login to our portal.",
              errorMessage: "",
              final_login_url: response.data.login_url,
              showLoginButton: true,
              pin_submit_button_loader: false,
            });
            /*setTimeout(() => {
                window.location.reload();
              }, 5000);*/
            //this.nextStep();
          }
          this.setState({
            pin_submit_button_loader: false,
          });
        });
    } catch (error) {
      this.setState({
        errorMessage: "There is some error. Please try again later.",
        disabled: false,
        successMessage: "",
        verifyPasswordButton: false,
      });
      setTimeout(() => {
        this.setState({
          errorMessage: "",
        });
      }, 4000);
      console.log("catch : ", error);
    }

    //}
  };

  resendPincode = async () => {
    //e.preventDefault();
    let { rfq_email, reseller_url } = this.state;

    this.setState({
      resendPinButton: true,
      redirectToClientPortalBtn: false,
      show_resend_button: false,
    });
    if (this.fieldValidationUsername() === true) {
      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "request_for_quote/resend_pincode",
            JSON.stringify({
              email: rfq_email,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
              reseller_url: reseller_url,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("resendPincode response.data : ", response.data);
            if (response.data.status === 404 || response.data.status === 422) {
              this.setState({
                errorMessage: "Invalid Email OR Username",
                disabled: false,
                resendPinButton: false,
                successMessage: "",
                show_resend_button: true,
                redirectToClientPortalBtn: true,
              });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            }

            if (response.data.status === 403) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
                resendPinButton: false,
                successMessage: "",
                show_resend_button: true,
                redirectToClientPortalBtn: true,
              });
              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
            }

            if (
              response.data.status === 200 &&
              response.data.message === "Account Already Exists"
            ) {
              this.setState({
                errorMessage: response.data.message,
                disabled: false,
                resendPinButton: false,
                successMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  `We have sent an email to ` +
                  rfq_email +
                  `. To complete the verification process, please check your inbox and enter the code you received here.`,
                errorMessage: "",
                fieldsHide: true,
                resendPinButton: false,
                resend_pin_text: "You can resend the pincode in 120 seconds.",
                countdown: 120,
              });

              setTimeout(() => {
                this.setState({
                  errorMessage: "",
                });
              }, 4000);
              setTimeout(() => {
                this.setState({
                  show_resend_button: true,
                  redirectToClientPortalBtn: true,
                });
              }, 120000);
              setInterval(() => {
                this.setState((prevState) => ({
                  countdown: prevState.countdown - 1,
                }));
              }, 1000);
            }
          });
      } catch (error) {
        this.setState({
          errorMessage: "There is some error. Please try again later.",
          disabled: false,
          successMessage: "",
          show_resend_button: true,
          redirectToClientPortalBtn: true,
          resendPinButton: false,
        });
        setTimeout(() => {
          this.setState({
            errorMessage: "",
          });
        }, 4000);
        console.log("catch : ", error);
      }
    }
  };

  fieldValidationUsername = () => {
    let { username, email } = this.state;

    if (email === "") {
      this.setState({
        VerifyUsernameButton: false,
        errorMessageEmail: "Email is required.",
      });
      return false;
    } else if (ValidateEmail(email) === false) {
      this.setState({
        VerifyUsernameButton: false,
        errorMessageEmail: "Invalid email format.",
      });
      return false;
    } else {
      this.setState({
        errorMessageEmail: "",
        disabled: true,
      });
    }
    return true;
  };

  nextStep = (step_title, step_index) => {
    const errorelements = document.querySelectorAll(".field_errorMsg");
    errorelements.forEach((element) => {
      element.remove();
    });
    this.setState({ currentStep: step_title, currentStepIndex: step_index });
  };

  prevStep = (step_title, step_index) => {
    const errorelements = document.querySelectorAll(".field_errorMsg");
    errorelements.forEach((element) => {
      element.remove();
    });
    this.setState({ currentStep: step_title, currentStepIndex: step_index });
  };
  firstStep = (step_title) => {
    this.setState({
      currentStep: step_title,
    });
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangePhone = (event) => {
    this.setState({
      rfq_phone: event,
    });
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;
    this.setState({
      rfq_country: country,
    });
  };

  handleChangeRadio = (value) => {
    this.setState({
      already_registered: value,
    });
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "delivery_date",
      date.toISOString().slice(0, 10)
    );
    //console.log("delivery_date " + this.state.delivery_date);
  };

  handleStatesDynamic = async (delivery_date, date) => {
    this.setState({
      [delivery_date]: date,
    });
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".pdf",
      ".doc",
      ".docx",
      ".csv",
      ".xlsx",
      ".xls",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        docErrorMessage:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 50MB)
    const maxSizeBytes = 50 * 1024 * 1024; // 50MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        docErrorMessage:
          "File size exceeds the limit (50MB). Please choose a smaller file.",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      docErrorMessage: "",
      add_picture_name: selectedFile.name,
    });
    //return true;
  };

  handleChangePin = (value, index, pincodes) => {
    const newPinCode = [...pincodes];
    newPinCode[index] = value;
    this.setState({ pincode: newPinCode.join("") });
    //console.log("handleChangePin: ", newPinCode.join(""));
  };

  handleChangePinComplete = (value, index, pincodes) => {
    const newPinCode = [...pincodes];
    newPinCode[index] = value;
    this.setState({ pincode: newPinCode.join("") });
    this.verifyPincode();
    //console.log("handleChangePinComplete: ", newPinCode.join(""));
  };

  handleChangeGeneric = (idx) => (e) => {
    const { name, value } = e.target;
    const file_upload_rows = [...this.state.file_upload_rows];

    file_upload_rows[idx][name] = value;
    //console.log("file_upload_rows[idx][name]: ", name);

    this.setState({
      file_upload_rows,
      isBlocking: false,
    });
    //
  };

  handleRemoveSpecificRowGeneric = (idx) => () => {
    const file_upload_rows = [...this.state.file_upload_rows];
    file_upload_rows.splice(idx, 1);
    this.setState({ file_upload_rows });
  };

  handleAddRowGeneric = () => {
    const item = {
      generic_title: "",
      generic_type: "",
      generic_rate: "",
      generic_buy_rate: "",
      pay_type: "",
    };
    this.setState({
      file_upload_rows: [...this.state.file_upload_rows, item],
    });
    //console.log("handleAddRowGeneric: ", this.state.generic_rows);
  };

  handleChangeRfq = (idx) => (e) => {
    //const { rfq_questions_rows } = this.state;
    let rfq_questions_rows = this.rfq_questions_rows;
    const { name, value } = e.target;

    let selected_key = name;
    if (rfq_questions_rows && rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        var mm = Object.entries(rfq_questions_rows[i]);
        console.log("selected_key: " + selected_key);
        if (selected_key in rfq_questions_rows[i]) {
          rfq_questions_rows[i][selected_key] = value;
          if (value !== "") {
            rfq_questions_rows[i].question_answered = true;
          } else {
            rfq_questions_rows[i].question_answered = false;
          }
        }
      }
    }
    this.rfq_questions_rows = rfq_questions_rows;
    this.setState({
      rfq_questions_rows: rfq_questions_rows,
    });
    //
  };

  handleChangeRfqCheckboxes = (idx) => (e) => {
    //const { rfq_questions_rows } = this.state;

    const { target } = e;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    console.log(
      "target.checked target.value: " + target.checked + " " + target.value
    );
    let rfq_questions_rows = this.rfq_questions_rows;
    let selected_key = name;
    if (rfq_questions_rows && rfq_questions_rows.length > 0) {
      for (let i = 0; i < rfq_questions_rows.length; i++) {
        console.log("selected_key: " + selected_key + " " + value);
        if (selected_key in rfq_questions_rows[i]) {
          console.log(
            "rfq_questions_rows[i]: " +
              JSON.stringify(rfq_questions_rows[i][selected_key])
          );
          if (target.checked) {
            rfq_questions_rows[i].selected_key = rfq_questions_rows[i][
              selected_key
            ].push(Number(target.value));
            rfq_questions_rows[i].question_answered = true;
          } else {
            var index = rfq_questions_rows[i][selected_key].indexOf(
              Number(target.value)
            );
            if (index !== -1) {
              rfq_questions_rows[i][selected_key].splice(index, 1);
            }
            if (rfq_questions_rows[i][selected_key].length === 0) {
              rfq_questions_rows[i].question_answered = false;
            }
            //rfq_questions_rows[i][selected_key] = value;
            //rfq_questions_rows[i].question_answered = false;
          }
        }
      }
    }
    this.rfq_questions_rows = rfq_questions_rows;
    this.setState({
      rfq_questions_rows: rfq_questions_rows,
    });
    console.log("rfq_questions_rows: ", this.rfq_questions_rows);
    //
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      rfq_comments: content,
    });
    //console.log("Content was updated:", content);
  };

  addNewService = async () => {
    const { select_service, services_rows } = this.state;
    //console.log("select_service: ", select_service);
    if (select_service !== "") {
      if (services_rows && services_rows.length > 0) {
        for (let i = 0; i < services_rows.length; i++) {
          if (services_rows[i].service_id == select_service) {
            services_rows[i].service_checked = true;
          }
        }
      }
      //console.log("addNewService services_rows: ", services_rows);
      this.setState({
        services_rows: services_rows,
        select_service: "",
      });
    }
  };

  removeService = async (remove_service) => {
    const { services_rows } = this.state;

    if (services_rows && services_rows.length > 0) {
      for (let i = 0; i < services_rows.length; i++) {
        if (services_rows[i].service_id == remove_service) {
          services_rows[i].service_checked = false;
        }
      }
    }
    //console.log("removeService services_rows: ", services_rows);
    this.setState({
      services_rows: services_rows,
      remove_service: "",
    });
  };

  handleChangeServices = (idx) => (e) => {
    const { name, value } = e.target;
    const services_rows = [...this.state.services_rows];

    let sub_total = 0;
    if (services_rows[idx]) {
      if (name === "service_quantity") {
        sub_total = services_rows[idx].service_price * parseInt(value);
        services_rows[idx].service_sub_total = sub_total;
      }
      services_rows[idx][name] = value;
    } else {
      this.setState((prevState) => {
        const services_rows = [...prevState.services_rows];
        if (services_rows[idx]) {
          services_rows[idx][name] = value;
        }
      });
    }

    this.setState({
      services_rows,
      isBlocking: false,
    });
    if (name === "service_quantity") {
      this.calculateTotalRate();
    }

    //
  };

  calculateTotalRate = async () => {
    const { services_rows, discount_value_total } = this.state;

    let total = 0;

    if (services_rows && services_rows.length > 0) {
      for (let i = 0; i < services_rows.length; i++) {
        if (services_rows[i].service_checked === true) {
          total =
            total +
            parseFloat(services_rows[i].service_price) *
              parseInt(services_rows[i].service_quantity);
        }
      }
    }
    let with_discount = total - parseFloat(discount_value_total);
    //Calculate Tax
    let tax_value_total = this.state.tax_value_total;
    with_discount = with_discount + parseFloat(tax_value_total);
    //Calculate Shipping
    let shipping_value_total = this.state.shipping_value_total;
    with_discount = with_discount + parseFloat(shipping_value_total);

    //Calculate Shipping and Discount

    //with_discount = with_discount + total_tax;
    console.table({
      with_discount_shipping_tax: with_discount,
      discount_value_total: discount_value_total,
      tax_value_total: tax_value_total,
      shipping_value_total: shipping_value_total,
    });

    this.setState({
      service_grand_total:
        with_discount && with_discount > 0 ? with_discount.toFixed(2) : "0.00",
      service_grand_sub_total: total && total > 0 ? total.toFixed(2) : "0.00",
    });
  };

  handleChangeServicesCheckbox = (idx) => (e) => {
    const { target } = e;
    const services_rows = [...this.state.services_rows];
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    if (services_rows[idx]) {
      services_rows[idx][name] = value;
    } else {
      this.setState((prevState) => {
        const services_rows = [...prevState.services_rows];
        if (services_rows[idx]) {
          services_rows[idx][name] = value;
        }
      });
    }

    this.setState({
      services_rows,
      isBlocking: false,
    });
    if (name === "service_checked") {
      this.calculateTotalRate();
    }

    //
  };

  closePopup = () => {
    window.$("#modalFormServices").modal("hide");
  };

  openServices = () => {
    window.$("#modalFormServices").modal("show");
  };

  redirectToClientPortal = () => {
    let redirect_url =
      process.env.REACT_APP_AGENT_PORTAL_URL +
      this.state.theme_data.reseller_detail[0].reseller_domain_identifier +
      "/client/login";
    window.location.href = redirect_url;
    // console.log("redirect_url: ", redirect_url);
  };

  redirectToSharelink = () => {
    let currentUrl = window.location.href;
    let sharelinkUrl = currentUrl.replace("/rfq/", "/home/");
    window.location.replace(sharelinkUrl);
  };

  showServiceDescription = async (service_id) => {
    let agent_url = this.props.match.params.agent_url;
    let agent_hash = this.props.match.params.url_hash;
    this.setState({
      errorMessageModal: "",
      successMessageModal: "",
    });
    const servicesResponce = await getAgentServiceDescription(
      auth.getToken(),
      agent_url,
      agent_hash,
      service_id
    );

    console.log("getAgentServiceDescription -> : ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessageModal:
          "There is some error while getting the service data.",
        successMessageModal: "",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.product_desc,
        tableLoader: false,
        tableLoaderListing: false,
        errorMessageModal: "",
      });
    } else {
      this.setState({
        errorMessageModal:
          "There is some error while getting the service data.",
        successMessageModal: "",
      });
    }
    window.$("#modalFormDetailDescription").modal("show");
  };

  closeModal = (modalId) => {
    window.$(`#${modalId}`).modal("hide");
  };

  render() {
    /* if (auth.isLogin() === true) {
      return <Redirect to="/dashboard" />;
    }*/

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <meta name="description" content="Request For Quotation" />
            <title>
              Request For Quotation -{" "}
              {this.state.theme_data.reseller_detail &&
              this.state.theme_data.reseller_detail[0].reseller_comp !== ""
                ? this.state.theme_data.reseller_detail[0].reseller_comp
                : ""}
            </title>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/css/theme.css?ver=3.0.3"
            />
            <script src="../../assets/ui_latest/js/apps/file-manager.js?ver=3.0.3"></script>
          </Helmet>
        </HelmetProvider>
        <div className="nk-app-root" id="start_container">
          {this.state.invoiceLoader === true ? (
            FormLoader()
          ) : (
            <div className="nk-main ">
              <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content ">
                  <div className="nk-split nk-split-page nk-split-lg">
                    <div className="nk-split-content nk-split-stretch bg-white p-3 d-flex align-center flex-column">
                      <div className="wide-xs-fix">
                        <div className="brand-logo pb-5  align-center mt-4">
                          <a href="/" className="logo-link">
                            {this.state.theme_data.logos ? (
                              <>
                                <img
                                  src={`${process.env.REACT_APP_AGENT_PORTAL_URL}/files_data/sharelinks/${this.state.theme_data.logos[0]["picture"]}`}
                                  className="logo-light logo-img logo-img-lg"
                                  alt={
                                    this.state.theme_data.logos[0][
                                      "image_title"
                                    ]
                                  }
                                  onClick={(event) => {
                                    event.preventDefault();
                                    this.redirectToSharelink();
                                  }}
                                />
                                <img
                                  src={`${process.env.REACT_APP_AGENT_PORTAL_URL}/files_data/sharelinks/${this.state.theme_data.logos[0]["picture"]}`}
                                  className="logo-dark logo-img logo-img-lg"
                                  alt={
                                    this.state.theme_data.logos[0][
                                      "image_title"
                                    ]
                                  }
                                  onClick={(event) => {
                                    event.preventDefault();
                                    this.redirectToSharelink();
                                  }}
                                />
                              </>
                            ) : (
                              <img
                                src="../../../assets/img/logo/dark.png"
                                alt=""
                                className="logo-light logo-img logo-img-lg"
                                onClick={(event) => {
                                  event.preventDefault();
                                  this.redirectToSharelink();
                                }}
                              />
                            )}
                          </a>
                        </div>
                        <h3 className="image-side-txt">
                          {" "}
                          Request For Quotation
                        </h3>
                        <p
                          style={{
                            color: "rgb(99, 115, 129)",
                            marginTop: "25px",
                          }}
                          className="rfq_description"
                        >
                          {" "}
                          To provide you with an accurate and competitive quote,
                          please share detailed information in your RFQ about
                          your project's scope, objectives, and specific
                          requirements. Your insights are crucial for us to
                          tailor our services effectively to meet your unique
                          needs and business goals.
                        </p>
                        {this.state.errorMessage !== "" ? (
                          <div className="alert alert-pro alert-danger mt-5 mb-5">
                            <div className="alert-text">
                              <h6>{this.state.errorMessageTitle}</h6>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessage !== "" ? (
                          <div className="alert alert-pro alert-success mt-5 mb-5">
                            <div className="alert-text">
                              <h6>Success</h6>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        ) : null}
                        {this.state.additionalErrorMessage !== "" ? (
                          <div className="alert alert-pro alert-danger mt-5 mb-5">
                            <div className="alert-text">
                              <h6>Error</h6>
                              <p>{this.state.additionalErrorMessage}</p>
                            </div>
                          </div>
                        ) : null}

                        {/* <div className="nk-block">
                          <div className="card card-bordered">
                            <div className="nk-kycfm">
                              <div
                                className="nk-kycfm-head"
                                style={{ background: "#f5f6fa" }}
                              >
                                <div className="nk-kycfm-count">Testing</div>
                                <div className="nk-kycfm-title">
                                  <h5 className="title">Testing</h5>
                                  <p className="sub-title">
                                    Testing
                                  </p>
                                </div>
                              </div>
                              <div className="nk-kycfm-content"></div>
                            </div>
                          </div>
                        </div> */}

                        <div className="nk-stepper-content">
                          <div className="nk-stepper-progress stepper-progress mb-4">
                            <div className="stepper-progress-count mb-2"></div>
                            <div className="progress progress-md">
                              <div className="progress-bar stepper-progress-bar"></div>
                            </div>
                          </div>
                          <div className="nk-stepper-steps stepper-steps">
                            {/****** START STEP 1 *****/}
                            {this.state.currentStep !== "" &&
                            this.state.currentStep !== "extra" &&
                            this.state.currentStep !== "pincode" ? (
                              <div key="questionsKey">
                                <div className="nk-stepper-step active">
                                  {this.rfq_questions_groups
                                    .filter(
                                      (question) =>
                                        question.group_id ===
                                        this.state.currentStep
                                    )
                                    .map((rfq, index) => (
                                      <div
                                        className="nk-stepper-step active customStyle-groupHeader"
                                        key={index}
                                      >
                                        <div className="customStyle-groupHeader-counter">
                                          {this.state.currentStepIndex}
                                        </div>
                                        <div>
                                          <h5 className="">
                                            {rfq.group_title}
                                          </h5>
                                          <p
                                            className=""
                                            style={{
                                              color: "rgb(99, 115, 129)",
                                            }}
                                          >
                                            {rfq.group_desc}
                                          </p>
                                        </div>
                                      </div>
                                    ))}
                                  <div className="row g-gs">
                                    {this.rfq_questions_rows &&
                                    this.rfq_questions_rows.length > 0 ? (
                                      <>
                                        {this.rfq_questions_rows
                                          .filter(
                                            (rfq) =>
                                              rfq.group_id ===
                                              this.state.currentStep
                                          )
                                          .map((rfq, bid) => (
                                            <div
                                              className="col-sm-6"
                                              key={`question_${bid}`}
                                            >
                                              <div className="form-group">
                                                <label className="form-label">
                                                  {bid + 1}.{" "}
                                                  {rfq.question_title}{" "}
                                                  {rfq.isMandatory === 1 && (
                                                    <sup className="text-danger">
                                                      *
                                                    </sup>
                                                  )}
                                                </label>
                                              </div>
                                              {rfq.question_type === "radio" ? (
                                                <>
                                                  {rfq.all_answers &&
                                                  rfq.all_answers.length > 0 ? (
                                                    <>
                                                      {rfq.all_answers.map(
                                                        (rfq_question, rid) => (
                                                          <div
                                                            className="form-group"
                                                            key={`options_${rid}`}
                                                          >
                                                            <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                                              <input
                                                                type="radio"
                                                                className={`custom-control-input ${
                                                                  rfq.isMandatory ===
                                                                  1
                                                                    ? "answer_required"
                                                                    : ""
                                                                }`}
                                                                name={`selected_answer_${rfq.question_id}`}
                                                                id={`question_${rid}_${bid}`}
                                                                onChange={this.handleChangeRfq(
                                                                  rid
                                                                )}
                                                                checked={
                                                                  rfq_question.id ===
                                                                  Number(
                                                                    rfq[
                                                                      `selected_answer_${rfq.question_id}`
                                                                    ]
                                                                  )
                                                                    ? true
                                                                    : false
                                                                }
                                                                value={
                                                                  rfq_question.id
                                                                }
                                                              />
                                                              <label
                                                                className="custom-control-label"
                                                                htmlFor={`question_${rid}_${bid}`}
                                                              >
                                                                {
                                                                  rfq_question.question_options
                                                                }
                                                              </label>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </>
                                                  ) : null}
                                                </>
                                              ) : rfq.question_type ===
                                                "checkbox" ? (
                                                <>
                                                  {rfq.all_answers &&
                                                  rfq.all_answers.length > 0 ? (
                                                    <>
                                                      {rfq.all_answers.map(
                                                        (rfq_question, cid) => (
                                                          <div
                                                            className="form-group"
                                                            key={`options_${cid}`}
                                                          >
                                                            <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                                              <input
                                                                type="checkbox"
                                                                className={`custom-control-input vdrSelected ${
                                                                  rfq.isMandatory ===
                                                                  1
                                                                    ? "answer_required"
                                                                    : ""
                                                                }`}
                                                                name={`selected_answer_${rfq.question_id}`}
                                                                onChange={this.handleChangeRfqCheckboxes(
                                                                  cid
                                                                )}
                                                                id={`question_${cid}_${bid}`}
                                                                checked={
                                                                  rfq[
                                                                    `selected_answer_${rfq.question_id}`
                                                                  ].includes(
                                                                    rfq_question.id
                                                                  )
                                                                    ? true
                                                                    : false
                                                                }
                                                                value={
                                                                  rfq_question.id
                                                                }
                                                              />
                                                              <label
                                                                className="custom-control-label"
                                                                htmlFor={`question_${cid}_${bid}`}
                                                              >
                                                                {
                                                                  rfq_question.question_options
                                                                }
                                                              </label>
                                                            </div>
                                                          </div>
                                                        )
                                                      )}
                                                    </>
                                                  ) : null}
                                                </>
                                              ) : rfq.question_type ===
                                                "textfield" ? (
                                                <>
                                                  <div className="form-group">
                                                    <div className="form-control-wrap">
                                                      <input
                                                        id={`question_0_${bid}`}
                                                        className={`form-control form-control-lg ${
                                                          rfq.isMandatory === 1
                                                            ? "answer_required"
                                                            : ""
                                                        }`}
                                                        name={`selected_answer_${rfq.question_id}`}
                                                        defaultValue={
                                                          rfq[
                                                            `selected_answer_${rfq.question_id}`
                                                          ]
                                                        }
                                                        onChange={this.handleChangeRfq(
                                                          0
                                                        )}
                                                        type="text"
                                                        placeholder=""
                                                        maxLength={100}
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                              ) : rfq.question_type ===
                                                "textarea" ? (
                                                <div className="form-group">
                                                  <div className="form-control-wrap">
                                                    <textarea
                                                      className={`form-control form-control-lg ${
                                                        rfq.isMandatory === 1
                                                          ? "answer_required"
                                                          : ""
                                                      }`}
                                                      id={`question_0_${bid}`}
                                                      name={`selected_answer_${rfq.question_id}`}
                                                      onChange={this.handleChangeRfq(
                                                        0
                                                      )}
                                                      defaultValue={
                                                        rfq[
                                                          `selected_answer_${rfq.question_id}`
                                                        ]
                                                      }
                                                      placeholder=""
                                                      maxLength={500}
                                                    ></textarea>
                                                  </div>
                                                </div>
                                              ) : rfq.question_type ===
                                                "dropdown" ? (
                                                <>
                                                  <div className="form-group">
                                                    <div className="form-control-wrap">
                                                      <div className="form-control-select">
                                                        <select
                                                          className={`form-control form-control-lg ${
                                                            rfq.isMandatory ===
                                                            1
                                                              ? "answer_required"
                                                              : ""
                                                          }`}
                                                          id={`question_0_${bid}`}
                                                          name={`selected_answer_${rfq.question_id}`}
                                                          label="Select an Option"
                                                          defaultValue={Number(
                                                            rfq[
                                                              `selected_answer_${rfq.question_id}`
                                                            ]
                                                          )}
                                                          onChange={this.handleChangeRfq(
                                                            0
                                                          )}
                                                        >
                                                          <option
                                                            key="sets100"
                                                            value=""
                                                          >
                                                            Select an option{" "}
                                                          </option>
                                                          {rfq.all_answers.map(
                                                            (
                                                              rfq_question,
                                                              rid
                                                            ) => (
                                                              <option
                                                                key={`sets${rid}`}
                                                                value={
                                                                  rfq_question.id
                                                                }
                                                              >
                                                                {
                                                                  rfq_question.question_options
                                                                }
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : null}
                                            </div>
                                          ))}
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <ul
                                  className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end border-top mt-5"
                                  style={{ marginBottom: "50px" }}
                                >
                                  {this.state.currentStep ===
                                  this.rfq_questions_groups[0]?.group_id ? (
                                    <li className="step-next">
                                      <button
                                        className="btn btn-lg btn-outline-primary"
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.redirectToSharelink();
                                        }}
                                      >
                                        <em className="icon ni ni-arrow-left"></em>
                                        <span>Back</span>
                                      </button>
                                    </li>
                                  ) : (
                                    <li className="step-prev">
                                      <button
                                        type="button"
                                        className="btn btn-dim btn-lg btn-block btn-primary"
                                        onClick={() => {
                                          const currentIndex =
                                            this.rfq_questions_groups.findIndex(
                                              (group) =>
                                                group.group_id ===
                                                this.state.currentStep
                                            );
                                          if (currentIndex > 0) {
                                            const prevGroup =
                                              this.rfq_questions_groups[
                                                currentIndex - 1
                                              ];
                                            this.prevStep(
                                              prevGroup.group_id,
                                              currentIndex
                                            );
                                          }
                                        }}
                                      >
                                        <em className="icon ni ni-step-back"></em>
                                        <span>Back</span>
                                      </button>
                                    </li>
                                  )}

                                  <li className="step-next">
                                    <button
                                      className="btn btn-lg btn-primary"
                                      onClick={() => this.submitRfq()}
                                    >
                                      <span>Continue</span>
                                      <em className="icon ni ni-arrow-right"></em>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            ) : this.state.currentStep === "extra" ? (
                              <div key="extraKey" id="start_container_additional">
                                <div className="nk-stepper-step active">
                                  <div className="nk-stepper-step active customStyle-groupHeader">
                                    <div className="customStyle-groupHeader-counter">
                                      {this.state.currentStepIndex}
                                    </div>
                                    <div>
                                      <h5 className="">Additional Details</h5>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 mb-4">
                                    <div className="row g-gs">
                                      <div className="col-sm-8">
                                        <div className="form-group">
                                          <label className="form-label">
                                            Select product and add
                                          </label>
                                          <div className="form-control-wrap">
                                            <select
                                              className="form-select form-control-lg js-select2"
                                              datasearch="on"
                                              name="select_service"
                                              id="select_service"
                                              onChange={this.handleChange}
                                              defaultValue={
                                                this.state.select_service
                                              }
                                            >
                                              <option value="default_option">
                                                Select product / service
                                              </option>
                                              {this.state.services_rows &&
                                              this.state.services_rows.length >
                                                0 ? (
                                                <>
                                                  {this.state.services_rows.map(
                                                    (service, sid) => (
                                                      <>
                                                        {service.service_checked ===
                                                        false ? (
                                                          <option
                                                            key={`ser${sid}`}
                                                            value={
                                                              service.service_id
                                                            }
                                                          >
                                                            {
                                                              service.service_title
                                                            }
                                                          </option>
                                                        ) : null}
                                                      </>
                                                    )
                                                  )}
                                                </>
                                              ) : null}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-sm-4">
                                        <div className="form-group">
                                          <label className="form-label">
                                            &nbsp;
                                          </label>
                                          <div className="form-control-wrap">
                                            <button
                                              type="button"
                                              className="btn btn-lg btn-primary"
                                              onClick={() =>
                                                this.addNewService()
                                              }
                                            >
                                              Add
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-gs">
                                    <div className="invoice-bills">
                                      <div className="table-responsive">
                                        <table className="table table-striped table-condensed">
                                          <thead>
                                            <tr>
                                              <th>Product Name</th>
                                              <th
                                                style={{
                                                  textAlign: "left",
                                                }}
                                              >
                                                Description
                                              </th>
                                              <th
                                                style={{
                                                  textAlign: "left",
                                                }}
                                              >
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.services_rows &&
                                            this.state.services_rows.length >
                                              0 ? (
                                              <>
                                                {this.state.services_rows.map(
                                                  (service, sid) => (
                                                    <>
                                                      {service.service_checked ===
                                                      true ? (
                                                        <tr key={`ser${sid}`}>
                                                          <td>
                                                            {
                                                              service.service_title
                                                            }{" "}
                                                          </td>
                                                          <td>
                                                            <div className="form-control-wrap">
                                                              {
                                                                <a
                                                                  onClick={() =>
                                                                    this.showServiceDescription(
                                                                      service.service_id
                                                                    )
                                                                  }
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    color:
                                                                      "#364a63",
                                                                  }}
                                                                >
                                                                  <span className="badge badge-outline-dark" style={{color: "#364a63"}}>
                                                                    View
                                                                  </span>
                                                                </a>
                                                              }
                                                            </div>
                                                          </td>

                                                          <td
                                                            style={{
                                                              textAlign: "left",
                                                            }}
                                                          >
                                                            <a
                                                              onClick={() =>
                                                                this.removeService(
                                                                  service.service_id
                                                                )
                                                              }
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                                color: "red",
                                                                fontSize:
                                                                  "22px",
                                                              }}
                                                            >
                                                              <em className="icon ni ni-trash-fill"></em>
                                                            </a>
                                                          </td>
                                                        </tr>
                                                      ) : null}
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : null}
                                          </tbody>
                                        </table>
                                      </div>
                                      
                                    </div>
                                  </div>
                                  <div className="col-lg-12 mt-4 mb-4">
                                    <div className="nk-block-head">
                                      <div className="nk-block-head-content d-flex">
                                        <div className="col-md-8 col-sm-12 col-xs-12">
                                          <h5 className="nk-block-title">
                                            Additional Information{" "}
                                          </h5>
                                        </div>

                                        <p>&nbsp;</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row g-3">
                                    <div className="col-sm-12">
                                      <div className="form-group">
                                        <label
                                          className="form-label"
                                          htmlFor="rfq_comments"
                                        >
                                          <Tooltip
                                            title="Describe your complete requirements in request for quotation."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Describe Your Requirements{" "}
                                          <sup>*</sup>
                                        </label>
                                        <div className="form-control-wrap">
                                          <textarea
                                            id="rfq_comments"
                                            name="rfq_comments"
                                            value={this.state.rfq_comments}
                                            onChange={this.handleChange}
                                            className="form-control"
                                            // style={{ height: "500px" }}
                                          />

                                          {/* <Editor
                                            id="rfq_comments"
                                            name="rfq_comments"
                                            apiKey="nb3xxzwletiuxjj0vxvl62020cjdnjddx0qmuck99x1knvbt"
                                            initialValue={
                                              this.state.rfq_comments
                                            }
                                            selector="textarea#full-featured"
                                            init={{
                                              height: 500,
                                              menubar: true,
                                              plugins: [
                                                "code print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                              ],
                                              toolbar:
                                                "code undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor casechange removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | showcomments addcomment",
                                            }}
                                            onEditorChange={
                                              this.handleEditorChange
                                            }
                                          /> */}
                                          <span
                                            id="rfq_comments_error"
                                            className="invalid"
                                            style={{ color: "red" }}
                                          >
                                            {this.state.rfq_comments_error}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-12 mt-4">
                                      <div className="nk-block-head">
                                        <div className="nk-block-head-content">
                                          <h5 className="nk-block-title ">
                                            Attachement{" "}
                                            <Tooltip
                                              title="User documentation"
                                              placement="right"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>
                                          </h5>
                                          <p>&nbsp;</p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="form-group">
                                        <div
                                          className="form-control-group"
                                          id="fileContainer"
                                        >
                                          <input
                                            type="file"
                                            id="add_picture"
                                            className="file-block"
                                            name="add_picture"
                                            defaultValue={
                                              this.state.add_picture
                                            }
                                            accept=".png, .jpg, .jpeg, .pdf, .doc, docx, .xlx, .xlsx, .csv"
                                            onChange={this.handleFileChange}
                                            required
                                          />
                                          <small>
                                            Acceptable file types: .png, .jpg,
                                            .jpeg, .pdf, .doc, .docx, .csv,
                                            .xlsx, .xls.
                                          </small>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="nk-block nk-block-lg  rfq_container  mt-4 pt-4">
                                      <div className="nk-block-head">
                                        <div className="nk-block-head-content">
                                          <h5 className="nk-block-title">
                                            Already have an account with us?
                                          </h5>
                                          <div className="nk-block-des">
                                            <p>
                                              Select one of the two options
                                              below and continue.
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card col-sm-12">
                                        <div className="card-inner">
                                          <ul className="custom-control-group">
                                            <li>
                                              <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                                <input
                                                  type="radio"
                                                  className="custom-control-input"
                                                  name="already_registered"
                                                  checked={
                                                    this.state
                                                      .already_registered ===
                                                    "new"
                                                      ? true
                                                      : false
                                                  }
                                                  id="btnRadio2"
                                                  defaultValue="new"
                                                  onChange={() =>
                                                    this.handleChangeRadio(
                                                      "new"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="btnRadio2"
                                                >
                                                  Register as a new user and
                                                  Submit RFQ.
                                                </label>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                                <input
                                                  type="radio"
                                                  className="custom-control-input"
                                                  name="already_registered"
                                                  checked={
                                                    this.state
                                                      .already_registered ===
                                                    "registered"
                                                      ? true
                                                      : false
                                                  }
                                                  id="btnRadio1"
                                                  defaultValue="registered"
                                                  onChange={() =>
                                                    this.handleChangeRadio(
                                                      "registered"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="btnRadio1"
                                                >
                                                  Already have an account
                                                </label>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.already_registered ===
                                    "registered" ? (
                                      <>
                                        <div className="col-lg-12">
                                          <div className="nk-block-head">
                                            <div className="nk-block-head-content">
                                              <h5 className="nk-block-title ">
                                                Contact Information{" "}
                                                <Tooltip
                                                  title="Please provide your email . If you don't have
                                                          an account with us, we will create one for you using this
                                                          information after the PIN verification."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>{" "}
                                              </h5>
                                              <p>
                                                Please provide your email
                                                address registered with us. We
                                                will send a verification to the
                                                given email for verification
                                                pupose.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_email"
                                            >
                                              Email Address <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="rfq_email"
                                                name="rfq_email"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_email
                                                }
                                                maxLength={100}
                                                placeholder=""
                                                required
                                              />
                                              <span
                                                id="rfq_email_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_email_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}

                                    {this.state.already_registered === "new" ? (
                                      <>
                                        <div className="col-lg-12" id="start_container_contact">
                                          <div className="nk-block-head">
                                            <div className="nk-block-head-content">
                                              <h5 className="nk-block-title ">
                                                Contact Information{" "}
                                                <Tooltip
                                                  title="Please provide your contact information. If you don't have
                                                        an account with us, we will create one for you using this
                                                        information after the PIN verification."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>{" "}
                                              </h5>
                                              <p>&nbsp;</p>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_full_name"
                                            >
                                              <Tooltip
                                                title="Only letters and numbers plus spaces, 2 to 64 characters"
                                                placement="right"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                              Contact Name <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="rfq_full_name"
                                                name="rfq_full_name"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_full_name
                                                }
                                                maxLength={64}
                                                minLength={2}
                                                onInput={(e) => {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      /[^a-zA-Z0-9 ]/gi,
                                                      ""
                                                    );
                                                }}
                                                placeholder=""
                                                autoComplete="off"
                                                required
                                              />
                                              <span
                                                id="rfq_full_name_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_full_name_error}
                                              </span>
                                              <small></small>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_company"
                                            >
                                              <Tooltip
                                                title="Only letters and numbers plus spaces, 2 to 64 characters"
                                                placement="right"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                              Company Name <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="rfq_company"
                                                name="rfq_company"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_company
                                                }
                                                onInput={(e) => {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      /[^a-zA-Z0-9 ]/gi,
                                                      ""
                                                    );
                                                }}
                                                maxLength={64}
                                                minLength={2}
                                                placeholder=""
                                                autoComplete="off"
                                                required
                                              />

                                              <span
                                                id="rfq_company_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_company_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_email"
                                            >
                                              Email Address <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="rfq_email"
                                                name="rfq_email"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_email
                                                }
                                                maxLength={100}
                                                autoComplete="off"
                                                placeholder=""
                                                required
                                              />
                                              <span
                                                id="rfq_email_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_email_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_phone"
                                            >
                                              <Tooltip
                                                title="Only numbers, 7 to 14 characters"
                                                placement="right"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                              Phone Number <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <PhoneInput
                                                defaultCountry="us"
                                                className="form-control form-control-lg"
                                                id="rfq_phone"
                                                name="rfq_phone"
                                                value={this.state.rfq_phone}
                                                onChange={
                                                  this.handleChangePhone
                                                }
                                                maxLength={14}
                                                minLength={7}
                                                placeholder=""
                                                required
                                                ref={this.phoneInputRef}
                                                onFocus={this.handleFocus}
                                              />
                                              <span
                                                id="rfq_phone_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_phone_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-4">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_address"
                                            >
                                              Address <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="rfq_address"
                                                name="rfq_address"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_address
                                                }
                                                maxLength={200}
                                                placeholder=""
                                                autoComplete="off"
                                                required
                                              />
                                              <span
                                                id="rfq_address_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_address_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_city"
                                            >
                                              City <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="rfq_city"
                                                name="rfq_city"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_city
                                                }
                                                maxLength={32}
                                                placeholder=""
                                                autoComplete="off"
                                                required
                                              />
                                              <span
                                                id="rfq_city_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_city_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_state"
                                            >
                                              State <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="rfq_state"
                                                name="rfq_state"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_state
                                                }
                                                maxLength={3}
                                                placeholder=""
                                                autoComplete="off"
                                                required
                                              />
                                              <span
                                                id="rfq_state_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_state_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_zip"
                                            >
                                              Zip / Postal Code <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="text"
                                                className="form-control form-control-lg"
                                                id="rfq_zip"
                                                name="rfq_zip"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_zip
                                                }
                                                maxLength={9}
                                                placeholder=""
                                                autoComplete="off"
                                                required
                                              />
                                              <span
                                                id="rfq_zip_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_zip_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-2">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_country"
                                            >
                                              Country <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <Select
                                                options={this.countryOptions}
                                                value={{
                                                  label: this.state.rfq_country,
                                                  value: this.state.rfq_country,
                                                }}
                                                onChange={
                                                  this.handleChangeCountry
                                                }
                                                menuPortalTarget={document.body}
                                                styles={{
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: 9999,
                                                  }), // Set a high z-index value
                                                }}
                                              />
                                              <span
                                                id="rfq_country_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_country_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_password"
                                            >
                                              <Tooltip
                                                title="Minimum 8 to 50 characters. Password should contain at least 1 number, 1 capital letter, 1 special character."
                                                placement="right"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                              Password <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                id="rfq_password"
                                                name="rfq_password"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state.rfq_password
                                                }
                                                maxLength={50}
                                                minLength={8}
                                                onInput={(e) => {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      /[^0-9a-zA-Z@&*$%()+!]/gi,
                                                      ""
                                                    );
                                                }}
                                                placeholder=""
                                                autoComplete="off"
                                                required
                                              />
                                              <span
                                                id="rfq_password_error"
                                                className="invalid"
                                              >
                                                {this.state.rfq_password_error}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-sm-6">
                                          <div className="form-group">
                                            <label
                                              className="form-label"
                                              htmlFor="rfq_confirm_password"
                                            >
                                              <Tooltip
                                                title="Minimum 8 to 50 characters. Password and confirm password must match. Password should contain at least 1 number, 1 capital letter, 1 special character."
                                                placement="right"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                              Confirm Password <sup>*</sup>
                                            </label>
                                            <div className="form-control-wrap">
                                              <input
                                                type="password"
                                                className="form-control form-control-lg"
                                                id="rfq_confirm_password"
                                                name="rfq_confirm_password"
                                                onChange={this.handleChange}
                                                defaultValue={
                                                  this.state
                                                    .rfq_confirm_password
                                                }
                                                maxLength={50}
                                                minLength={8}
                                                onInput={(e) => {
                                                  e.target.value =
                                                    e.target.value.replace(
                                                      /[^0-9a-zA-Z@&*$%()+!]/gi,
                                                      ""
                                                    );
                                                }}
                                                placeholder=""
                                                autoComplete="off"
                                                required
                                              />
                                              <span
                                                id="rfq_confirm_password_error"
                                                className="invalid"
                                              >
                                                {
                                                  this.state
                                                    .rfq_confirm_password_error
                                                }
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                </div>

                                <ul
                                  className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end  border-top mt-5"
                                  style={{ marginBottom: "50px" }}
                                >
                                  {this.rfq_questions_rows &&
                                  this.rfq_questions_rows.length > 0 ? (
                                    <li className="step-prev">
                                      <button
                                        type="button"
                                        className="btn btn-dim btn-lg btn-block btn-primary"
                                        onClick={() => {
                                          const lastGroupId =
                                            this.rfq_questions_groups[
                                              this.rfq_questions_groups.length -
                                                1
                                            ].group_id;
                                          const index =
                                            this.rfq_questions_groups.findIndex(
                                              (group) =>
                                                group.group_id === lastGroupId
                                            );
                                          this.prevStep(lastGroupId, index + 1);
                                        }}
                                      >
                                        <em className="icon ni ni-step-back"></em>
                                        <span>Back</span>
                                      </button>
                                    </li>
                                  ) : null}
                                  {this.state
                                    .extraDetails_submit_button_loader ? (
                                    <li className="step-next">
                                      <button className="btn btn-lg btn-primary">
                                        <span>Processing...</span>
                                      </button>
                                    </li>
                                  ) : (
                                    <li className="step-next">
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-block btn-primary"
                                        onClick={() =>
                                          this.submitExtraDetails()
                                        }
                                      >
                                        <span>Continue</span>
                                        <em className="icon ni ni-arrow-right"></em>
                                      </button>
                                    </li>
                                  )}
                                </ul>
                                {/* SERVICES MODEL */}
                                {/* <div
                                  className="modal fade"
                                  id="modalFormServices"
                                >
                                  <div
                                    className="modal-dialog modal-dialog-top modal-xl"
                                    role="document"
                                  >
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">
                                          Add product / service
                                        </h5>
                                        <a
                                          href="#"
                                          className="close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <em className="icon ni ni-cross"></em>
                                        </a>
                                      </div>
                                      <div className="modal-body">
                                        <div className="col-sm-12 mb-4">
                                          <div className="row g-gs">
                                            <div className="col-sm-8">
                                              <div className="form-group">
                                                <label className="form-label">
                                                  Select product and add
                                                </label>
                                                <div className="form-control-wrap">
                                                  <select
                                                    className="form-select form-control-lg js-select2"
                                                    datasearch="on"
                                                    name="select_service"
                                                    id="select_service"
                                                    onChange={this.handleChange}
                                                    defaultValue={
                                                      this.state.select_service
                                                    }
                                                  >
                                                    <option value="default_option">
                                                      Select product / service
                                                    </option>
                                                    {this.state.services_rows &&
                                                    this.state.services_rows
                                                      .length > 0 ? (
                                                      <>
                                                        {this.state.services_rows.map(
                                                          (service, sid) => (
                                                            <>
                                                              {service.service_checked ===
                                                              false ? (
                                                                <option
                                                                  key={`ser${sid}`}
                                                                  value={
                                                                    service.service_id
                                                                  }
                                                                >
                                                                  {
                                                                    service.service_title
                                                                  }
                                                                </option>
                                                              ) : null}
                                                            </>
                                                          )
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-sm-4">
                                              <div className="form-group">
                                                <label className="form-label">
                                                  &nbsp;
                                                </label>
                                                <div className="form-control-wrap">
                                                  <button
                                                    type="button"
                                                    className="btn btn-lg btn-primary"
                                                    onClick={() =>
                                                      this.addNewService()
                                                    }
                                                  >
                                                    Add
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row g-gs">
                                          <div className="invoice-bills">
                                            <div className="table-responsive">
                                              <table className="table table-striped table-condensed">
                                                <thead>
                                                  <tr>
                                                    <th>Product Name</th>
                                                    <th
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      Qty
                                                    </th>
                                                    <th
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      Unit
                                                    </th>
                                                    <th
                                                      style={{
                                                        textAlign: "left",
                                                      }}
                                                    >
                                                      Action
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.services_rows &&
                                                  this.state.services_rows
                                                    .length > 0 ? (
                                                    <>
                                                      {this.state.services_rows.map(
                                                        (service, sid) => (
                                                          <>
                                                            {service.service_checked ===
                                                            true ? (
                                                              <tr
                                                                key={`ser${sid}`}
                                                              >
                                                                <td>
                                                                  {
                                                                    service.service_title
                                                                  }
                                                                </td>

                                                                <td>
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="service_quantity"
                                                                      name="service_quantity"
                                                                      onChange={this.handleChangeServices(
                                                                        sid
                                                                      )}
                                                                      placeholder=""
                                                                      style={{
                                                                        width:
                                                                          "60px",
                                                                      }}
                                                                      value={
                                                                        service.service_quantity
                                                                      }
                                                                      required
                                                                    />
                                                                  </div>
                                                                </td>
                                                                <td>
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="service_unit"
                                                                      name="service_unit"
                                                                      onChange={this.handleChangeServices(
                                                                        sid
                                                                      )}
                                                                      placeholder=""
                                                                      onInput={(
                                                                        e
                                                                      ) => {
                                                                        e.target.value =
                                                                          e.target.value.replace(
                                                                            /[^a-zA-Z0-9]/gi,
                                                                            ""
                                                                          );
                                                                      }}
                                                                      style={{
                                                                        width:
                                                                          "60px",
                                                                      }}
                                                                      value={
                                                                        service.service_unit
                                                                      }
                                                                      required
                                                                    />
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    textAlign:
                                                                      "left",
                                                                  }}
                                                                >
                                                                  <a
                                                                    onClick={() =>
                                                                      this.removeService(
                                                                        service.service_id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      color:
                                                                        "red",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash-fill"></em>
                                                                  </a>
                                                                </td>
                                                              </tr>
                                                            ) : null}
                                                          </>
                                                        )
                                                      )}
                                                    </>
                                                  ) : null}
                                                </tbody>
                                              </table>
                                            </div>
                                            <div className="example-alert mt-5 mb-2">
                                              <div className="alert alert-light">
                                                <strong>NOTE:</strong> After
                                                adding the product, if you're
                                                unsure about the quantity, you
                                                can leave it as 0.
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="modal-footer bg-light">
                                        <div class="d-flex justify-content-end">
                                          <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={() => this.closePopup()}
                                          >
                                            Save & Close
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                {/* SERVICES MODEL */}
                              </div>
                            ) : this.state.currentStep === "pincode" ? (
                              <div key="questionsKey">
                                <div className="nk-stepper-step active">
                                  <div class="col-md-6 offset-md-3 text-center">
                                    <div class="card card-bordered">
                                      <div class="card-inner">
                                        <div className="d-flex justify-content-center  mb-4">
                                          <div className="user-card bg-lighter p-3 rounded-pill">
                                            <div className="user-name">
                                              <span
                                                className="tb-lead fs-16px"
                                                style={{
                                                  fontFamily:
                                                    "Nunito, sans-serif",
                                                }}
                                              >
                                                {this.state.rfq_email}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        {this.state.showLoginButton ===
                                        false ? (
                                          <h5 class="card-title">
                                            Enter the pin code
                                          </h5>
                                        ) : null}

                                        <div className="row g-3">
                                          {this.state.showLoginButton ===
                                          false ? (
                                            <>
                                              <div className="form-group  d-flex justify-content-center">
                                                <PinInput
                                                  length={4}
                                                  initialValue=""
                                                  focus
                                                  onChange={(value, index) => {
                                                    this.handleChangePin(
                                                      value,
                                                      index,
                                                      ""
                                                    );
                                                  }}
                                                  onCopy={(value, index) => {
                                                    this.handleChangePinComplete(
                                                      value,
                                                      index,
                                                      ""
                                                    );
                                                  }}
                                                  onBlur={(value, index) => {
                                                    this.handleChangePin(
                                                      value,
                                                      index,
                                                      ""
                                                    );
                                                  }}
                                                  onKeyDown={(value, index) => {
                                                    this.handleChangePin(
                                                      value,
                                                      index,
                                                      ""
                                                    );
                                                  }}
                                                  onComplete={(
                                                    value,
                                                    index
                                                  ) => {
                                                    this.handleChangePinComplete(
                                                      value,
                                                      index,
                                                      ""
                                                    );
                                                  }}
                                                  defaultValue={
                                                    this.state.pincode
                                                  }
                                                  id="pincode"
                                                  name="pincode"
                                                  type="numeric"
                                                  inputMode="number"
                                                  style={{ padding: "15px" }}
                                                  inputStyle={{
                                                    borderColor: "#dbdfea",
                                                  }}
                                                  inputFocusStyle={{
                                                    borderColor: "#6576ff",
                                                  }}
                                                  autoSelect={false}
                                                  regexCriteria={
                                                    /^[ A-Za-z0-9_@./#&+-]*$/
                                                  }
                                                />
                                              </div>
                                              <div className="form-group text-center  d-flex justify-content-center">
                                                <div
                                                  className="form-label-group"
                                                  style={{
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  <label
                                                    className="form-label"
                                                    htmlFor="password"
                                                  >
                                                    &nbsp;
                                                  </label>
                                                  {this.state
                                                    .show_resend_button ? (
                                                    <>
                                                      <a
                                                        onClick={() =>
                                                          this.resendPincode()
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "#798bff",
                                                        }}
                                                      >
                                                        {this.state
                                                          .resendPinButton ? (
                                                          <div
                                                            class="spinner-grow spinner-grow-sm"
                                                            role="status"
                                                          >
                                                            <span class="visually-hidden">
                                                              Loading...
                                                            </span>
                                                          </div>
                                                        ) : null}
                                                        Resend Pin Code{" "}
                                                      </a>
                                                      <label
                                                        className="form-label"
                                                        htmlFor="password"
                                                      >
                                                        OR
                                                      </label>
                                                      <a
                                                        onClick={() =>
                                                          this.redirectToClientPortal()
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                          color: "#798bff",
                                                        }}
                                                      >
                                                        Redirect to Portal{" "}
                                                      </a>
                                                    </>
                                                  ) : (
                                                    <p>
                                                      You can resend the pin
                                                      code in{" "}
                                                      {this.state.countdown}{" "}
                                                      seconds.
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          ) : this.state.showLoginButton ===
                                            true ? (
                                            <>
                                              <div class="example-alert">
                                                <div class="alert alert-success">
                                                  Your request for quotation is
                                                  submitted successfully. To
                                                  check the status of your
                                                  request, click on the "Go to
                                                  dashboard" button. Refresh the
                                                  page if you want to add
                                                  another RFQ.{" "}
                                                </div>
                                              </div>
                                            </>
                                          ) : null}
                                          <ul
                                            className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-center"
                                            style={{ marginBottom: "10px" }}
                                          >
                                            {this.state.showLoginButton ===
                                            true ? (
                                              <>
                                                <li className="step-next">
                                                  <a
                                                    className="btn btn-block btn-success"
                                                    href={
                                                      this.state.final_login_url
                                                    }
                                                    target="_blank"
                                                  >
                                                    Go to dashboard
                                                  </a>
                                                </li>
                                              </>
                                            ) : (
                                              <>
                                                {this.state
                                                  .verifyPasswordButton ? (
                                                  <li className="step-next">
                                                    <button
                                                      className="btn btn-lg btn-primary"
                                                      type="button"
                                                      disabled
                                                    >
                                                      <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                      ></span>
                                                      <span>Processing...</span>
                                                    </button>
                                                  </li>
                                                ) : (
                                                  <>
                                                    <li className="step-prev">
                                                      <button
                                                        type="button"
                                                        className="btn btn-dim btn-lg btn-primary"
                                                        onClick={() =>
                                                          this.prevStep(
                                                            "extra",
                                                            this
                                                              .rfq_questions_groups
                                                              .length + 1
                                                          )
                                                        }
                                                      >
                                                        <em class="icon ni ni-step-back"></em>
                                                        <span>Back</span>
                                                      </button>
                                                    </li>
                                                    {this.state
                                                      .pin_submit_button_loader ? (
                                                      <li className="step-next">
                                                        <button className="btn btn-lg btn-primary">
                                                          <span>
                                                            Processing...
                                                          </span>
                                                        </button>
                                                      </li>
                                                    ) : (
                                                      <li className="step-next">
                                                        <button
                                                          className="btn btn-lg btn-primary"
                                                          onClick={() =>
                                                            this.verifyPincode()
                                                          }
                                                        >
                                                          <span>Submit</span>
                                                          <em class="icon ni ni-arrow-right"></em>
                                                        </button>
                                                      </li>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            {/*
                            {(() => {
                              switch (this.state.step) {
                                default:
                                  return (
                                    <div className="alert alert-pro alert-danger mt-4">
                                      <div className="alert-text">
                                        <h6>Warning</h6>
                                        <p>
                                          Cannot load RFQ right now. Please
                                          check your internet connection and
                                          refresh the page.{" "}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                case 1:
                                  return (
                                    <>
                                      <Questions
                                        nextStep={this.nextStep}
                                        handleChange={this.handleChange}
                                        handleChangeRfq={this.handleChangeRfq}
                                        handleChangeRfqCheckboxes={
                                          this.handleChangeRfqCheckboxes
                                        }
                                        submitRfq={this.submitRfq}
                                        theme_data={this.state.theme_data}
                                        values={values}
                                      />
                                    </>
                                  );
                            
                                case 2:
                                  return (
                                    <>
                                      <ExtraDetails
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        submitExtraDetails={
                                          this.submitExtraDetails
                                        }
                                        addNewService={this.addNewService}
                                        removeService={this.removeService}
                                        handleRemoveSpecificRowGeneric={
                                          this.handleRemoveSpecificRowGeneric
                                        }
                                        handleEditorChange={
                                          this.handleEditorChange
                                        }
                                        handleAddRowGeneric={
                                          this.handleAddRowGeneric
                                        }
                                        handleChangeServices={
                                          this.handleChangeServices
                                        }
                                        handleChangeServicesCheckbox={
                                          this.handleChangeServicesCheckbox
                                        }
                                        handleFileChange={this.handleFileChange}
                                        handleChange={this.handleChange}
                                        handleChangeRadio={
                                          this.handleChangeRadio
                                        }
                                        handleDateChange={this.handleDateChange}
                                        theme_data={this.state.theme_data}
                                        values={values}
                                      />
                                    </>
                                  );
                                case 3:
                                  return (
                                    <>
                                      <Pin_verification
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        handleChangePin={this.handleChangePin}
                                        handleChange={this.handleChange}
                                        handleChangePinComplete={
                                          this.handleChangePinComplete
                                        }
                                        verifyPincode={this.verifyPincode}
                                        resendPincode={this.resendPincode}
                                        theme_data={this.state.theme_data}
                                        values={values}
                                      />
                                    </>
                                  );
                                //
                              }
                            })()}
                          */}
                            {/*  DETAILS MODAL*/}
                            <div
                              className="modal fade zoom"
                              tabIndex="-1"
                              id="modalFormDetailDescription"
                            >
                              <div
                                className="modal-dialog modal-xl modal-dialog-top"
                                role="document"
                              >
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <div
                                      className="modal-title"
                                      style={{
                                        textAlign: "center",
                                        fontSize: "20px",
                                      }}
                                    >
                                      <strong>
                                        <em className="icon ni ni-list-round"></em>{" "}
                                        Product Description
                                      </strong>
                                    </div>
                                    <a
                                      href="#"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                      onClick={() =>
                                        this.closeModal(
                                          "modalFormDetailDescription"
                                        )
                                      }
                                    >
                                      <em className="icon ni ni-cross"></em>
                                    </a>
                                  </div>

                                  <div className="modal-body">
                                    <div>
                                      <div className="card-inner-group">
                                        <div className="card-inner">
                                          <div className="sp-plan-head-group">
                                            <div className="sp-plan-head">
                                              {/* <h6 className="title">
                                              Short Description
                                            </h6> */}
                                              <span className="text-dark">
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      this.state.serviceData,
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* DETAILS MODAL */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <HelmetProvider>
          <Helmet>
            <script src="../../assets/ui_latest/js/bundle.js?ver=3.0.3"></script>
            <script src="../../assets/ui_latest/js/scripts.js?ver=3.0.3"></script>
          </Helmet>
        </HelmetProvider>
      </>
    );
  }
}
